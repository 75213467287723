import { LANG } from "../../common/types/localization";

export const exhibitions = {
  [LANG.deCH]: {
    exhibitions_title: "Ausstellungen",
    exhibitions_name: "Norbert Jan Paprotny",
    exhibitions_text_1:
      " geb. 6 Mai1928 Pawlow (jetzt Teil von Zabrze) - polnischer Maler",
    exhibitions_text_2:
      "1952 Maturitätsprüfung in Katowice. Künstlerische Studien absolvierte er mit Diplom an der Kunstakademie in Krakau von 1953 bis 1959. Mitglied des Polnischen Künstlerverbandes seit 1960. freischaffend tätig in Staffeleimalerei und Wandmalerei.",
    exhibitions_title_2: "Teilnahme an Ausstellungen",
    exhibitions_part_1: [
      "1961 – Ausstellung der Kunst in den Gebieten an der Oder – Wrocław (Breslau)",
      "1961 – Ausstellung der Malerei zum fünfzehnten Jahr der Volksrepublik Polen – Warschau",
      "1961 – Ausstellung des Bezirks – Katowice",
      "1961 – Ausstellung zum fünfzehnten Jahr des Polnischen Künstlerverbandes, Abteilung Gliwice",
      "1962 – 1. Festival der polnischen Malerei der Gegenwart – Szczecin",
      "1962 – Bezirksausstellung in Katowice",
      "1962 – Internationale Ausstellung der Malerei in Monte Carlo (Spezielle Auszeichnung der Jury)",
      "1962 – Individuelle Ausstellungen in Gliwice – Katowice – Bielsko",
      "1963 – Kunstausstellung im Odergebiet",
      "1964 – Ausstellung der Künstler von Zabrze – Zabrze",
      "1965 – Ausstellung des Künstlerverbandes von Gliwice – Zabrze",
      "1965 – 20 Jahre der Volksrepublik Polen in der Kunst – Katowice",
      "1965 – Nationale Ausstellung der Kunst im 20. Jahr der Volksrepublik Polen – Warschau",
      "1965 – Nationale Ausstellung der Malerei und Grafik – Bielsko-Biala"
    ],
    exhibitions_text_3:
      "In den Jahren 1959 - 1971 realisierte er Wandmalerei, Wandmalerei auf keramischen Platten und Mosaiken in über zwanzig Kirchen und öffentlichen Gebäuden.",
    exhibitions_text_4:
      "Im Februar 1972 verließ er Zabrze und liess sich in der Schweiz nieder. 1979 gründet er seine eigene Kunstschule in Zürich und führte sie bis zu seiner Pensionierung 1993",
    exhibitions_title_3: "Ausstellungen in der Schweiz und im Ausland",
    exhibitions_part_2: [
      "1976 – Galerie Klubschule Migros – Zürich",
      "1977 – Kunstausstellung Zürich-Land – Thalwil ZH",
      "1977 – Kunstszene Zürich – Zürich",
      "1978 – Galerie Les Muguets – Versoix GE",
      "1980 – Schloss Mainz – Deutschland",
      "1984 – Galerie Im Hof – Rüschlikon ZH",
      "1989 – Galerie NatWest Bank – Zürich",
      "1990 – Salon Volvo Sirnach – Sirnach TG",
      "1992 – Studio Athletic 46 – Zürich",
      "1994 – Im Keller bei Inez und Felix Naglicki – Baden AG",
      "1996 – Zentrum St. Antonius – Wallisellen ZH",
      "2000 – Galerie Hochschule Wädenswil – Wädenswil ZH",
      "2000 – Polenmuseum Schloss Rapperswil – Rapperswil SG",
      "2001 – Borondon Kunstgalerie – Adliswil ZH",
      "2001 – Salon Volvo Kloten – Kloten ZH",
      "2003 – Bilderausstellung im Städtischen Museum von Zabrze PL",
      "2003 – Bilderausstellung im Museum der Unabhängigkeit in Warschau PL",
      "2003 – Bilderausstellung im Dwór BellaVita in Wola Zręczycka PL",
      "2004 – Bilderausstellung im Archediözesial-Museum in Katowice PL",
      "2009 – Bilderausstellung im Kulturzentrum Bülach ZH"
    ],
    exhibitions_text_5:
      "Seine Werke befinden sich im Städtischen Museum in Zabrze, im Museum Gliwice und in Privatsammlungen der Schweiz, Griechenland, USA, Frankreich und anderen Ländern."
  },
  [LANG.plPL]: {
    exhibitions_title: "Wystawy",
    exhibitions_name: "Norbert Jan Paprotny",
    exhibitions_text_1:
      " (ur. 1928 Pawłów (obecnie część Zabrza) — polski malarz.",
    exhibitions_text_2:
      "Maturę zdał w 1952 w Katowicach. 1953 rospoczął studia artystyczne na Akademii Sztuk Pięknych w Krakowie i ukończył dyplomem w 1959 roku. Członek Związku Polskich Artystów Plastyków od 1960 roku. Uprawia malarstwo sztalugowe i malarstwo architektoniczne.",
    exhibitions_title_2: "Udział w następujących wystawach",
    exhibitions_part_1: [
      "1961 – Wystawa Plastyki Ziem Nadodrzańskich – Wrocław",
      "1961 – Wystawa Malarstwa w XV-lecie PRL – Warszawa",
      "1961 – Wystawa Okręgowa – Katowice",
      "1961 – Wystawa XV-lecia Oddziału Gliwickiego – Gliwice",
      "1962 – I Festiwal Polskiego Malarstwa Współczesnego – Szczecin",
      "1962 – Wystawa Okręgowa – Katowice",
      "1962 – Międzynarodowa Wystawa Malarstwa – Monte Carlo (Specjalne wyróżnienie Jury)",
      "1962 – Wystawy indywidualne – Gliwice – Katowice – Bielsko",
      "1963 – Wystawa Plastyki Ziem Nadodrzańskich",
      "1964 – Wystawa Plastyków Zabrzańskich – Zabrze",
      "1965 – Wystawa Oddziału Gliwickiego – Gliwice – Zabrze",
      "1965 – XX lat PRL w Twórczości Plastycznej – Katowice",
      "1965 – Ogólnopolska Wystawa XX lat PRL w Twórczości Plastycznej – Warszawa",
      "1965 – Ogólnopolska Wystawa Malarstwa i Grafiki – Bielsko-Biała"
    ],
    exhibitions_text_3:
      "W latach 1959 - 1971 realizował malarstwo ścienne i malarstwo na płytach ceramicznych oraz mozaiki w ponad dwudziestu kościołach i budynkach publicznych na Śląsku i województwie kieleckim.",
    exhibitions_text_4:
      "W lutym 1972 roku opuścił Zabrze i osiedlił się w Szwajcarii. W 1979 roku otworzył własną szkołę artystyczną w Zurychu i prowadził ją do wieku emerytalnego w 1993 roku.",
    exhibitions_title_3: "Wystawy w Szwajcarii i innych krajach",
    exhibitions_part_2: [
      "1976 – Galeria Klubschule Migros Zurych",
      "1977 – Wystawa sztuki Zurych-Land Thalwil ZH",
      "1977 – Scena sztuki w Zurychu, Zurych",
      "1978 – Galeria Les Muguets Versoix GE",
      "1980 – Zamek Mainz, Niemcy",
      "1984 – Galeria Im Hof Rüschlikon ZH",
      "1989 – Galeria NatWest Bank, Zurych",
      "1990 – Salon Volvo, Sirnach TG",
      "1992 – Salon Atchletic 46, Zurych",
      "1994 – Piwnica u Inez J. i F. Naglickich, Baden AG",
      "1996 – Centrum St. Antonius, Wallisellen ZH",
      "2000 – Galeria Szkoła Wyższa Wädenswil ZH",
      "2000 – Muzeum Polskie w Zamku Rapperswil SG",
      "2001 – Borondon Fine Art Galerie, Adliswil ZH",
      "2001 – Salon Volvo, Kloten",
      "2003 – Wystawa malarstwa w Muzeum Miejskim w Zabrzu",
      "2003 – Wystawa malarstwa w Muzeum Niepodległości w Warszawie",
      "2003 – Wystawa malarstwa w Dworze Bella Vita w Woli Zręczyckiej",
      "2004 – Wystawa malarstwa w Muzeum Archidiecezjalnym w Katowicach",
      "2009 – Wystawa malarstwa w Centrum Kultury w Bülach"
    ],
    exhibitions_text_5:
      "Prace znajdują się m.in. w zbiorach Muzeum Miejskim w Zabrzu i w Muzeum w Gliwicach oraz w zbiorach prywatnych w Szwajcarii, Grecji, USA, Francji i w innych krajach."
  }
};
