import * as React from "react";
import { FC, ReactNode } from "react";
import { UnsupportedBrowserMessage } from "../../../common/components/unsupported-browser-message/unsupported-browser-message";
import { Header } from "./header";
import { Main } from "./main";
import { Footer } from "./footer";

interface ILayoutDefault {
  children: {
    header: ReactNode;
    main: ReactNode;
    footer: ReactNode;
  };
}

export const LayoutDefault: FC<ILayoutDefault> = ({ children }) => {
  const { header, main, footer } = children;
  return (
    <UnsupportedBrowserMessage>
      <div>
        <header>
          <Header>{header}</Header>
        </header>
        <main>
          <Main>{main}</Main>
        </main>
        <footer>
          <Footer>{footer}</Footer>
        </footer>
      </div>
    </UnsupportedBrowserMessage>
  );
};
