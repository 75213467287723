import * as React from "react";
import { FC, ReactNode } from "react";
import { LANG } from "../../../common/types/localization";
import classes from "./footer.module.css";
import { FooterNavigation } from "../../navigation/footer-navigation";

interface IFooter {
  children: ReactNode;
}

export const Footer: FC<IFooter> = ({ children }) => (
  <div className={classes.footer}>{children}</div>
);

interface IFooterContent {
  lang: LANG;
}

export const FooterContent: FC<IFooterContent> = ({ lang }) => (
  <FooterNavigation lang={lang} />
);
