import { LANG } from "../../common/types/localization";

export const legal = {
  [LANG.deCH]: {
    legal_title: "Rechtliche Hinweise",
    legal_section1_title: "Haftungsausschluss",
    legal_section1_paragraph:
      "Die Inhalte dieser Website werden vom Anbieter bestmöglich und mit Sorgfalt geprüft. Er übernimmt aber keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte. Nur mit der Nutzung der Website kommen keinerlei Vertragsverhältnisse zwischen dem Benutzer und dem Anbieter zustande.",
    legal_section2_title: "Weiterverwendung von Inhalten",
    legal_section2_paragraph:
      "Die auf dieser Website veröffentlichten Inhalte dürfen ohne ausdrückliche Genehmigung nicht weiterverwendet werden. Die gezeigten Bilder und Malereien von Norbert Jan Paprotny sind urheberrechtlich geschützt. Jegliche Reproduktion ohne Zustimmung des Künstlers ist untersagt und strafbar.",
    legal_section3_title: "Datenschutz",
    legal_section3_paragraph1:
      "Zwischen dem Benutzer und der Website werden Daten unverschlüsselt übermittelt auch im E-Mail-Verkehr werden in der Regel Daten unverschlüsselt übermittelt und sind daher grundsätzlich für Dritte einsehbar.",
    legal_section3_paragraph2:
      "Beim Besuch der Website erfassen wir Daten über den Benutzer. Diese Daten werden ausschliesslich in anonymisierter Form zu statistischen Zwecken weiterverwendet um die Website zu verbessern. IP-Adressen der Benutzer werden nur in maskierter Form gespeichert. Es findet keinerlei Weitergabe dieser Daten an Dritte statt.",
    legal_section4_title: "Abweichende Nutzungsbestimmungen",
    legal_section4_paragraph:
      "Es ist möglich, dass für einzelne Inhalte abweichende Nutzungsbedingungen gelten. Ist dies der Fall, wird an der betreffenden Stelle darauf hingewiesen."
  },
  [LANG.plPL]: {
    legal_title: "Wskazówki prawne",
    legal_section1_title: "Wykuczenie odpowiedzielności",
    legal_section1_paragraph:
      "Treści strony internetowej będą przez dostawcę z najlepszą możliwością i z rzetelnością badane. On nie bierze odpowiedzialności za prawidłowość, kompletność i aktualność treści. Z korzystania tylko ze strony internetowej nie zachodzą żadne umowy między użydkownikiem a dostawcą.",
    legal_section2_title: "Dalsze używanie treści",
    legal_section2_paragraph:
      "Treści tej strony internetowej nie mogą być używane bez jednoznacznego pozwolenia. Pokazane obrazy i malarstwa Norberta Jana Paprotnego są chronione prawem autorskim. Wszelkie reprodukowanie bez zgody artysty jest niedozwolone i karalne.",
    legal_section3_title: "Ochrona danych",
    legal_section3_paragraph1:
      "Między urzytkownikiem i stroną internetową będą przekazywane niezakodowane dane, również w komunikacji E-Mail będą z reguły niezakodowane dane przekazywane i dlatego są dla osób trzecich dostępne.",
    legal_section3_paragraph2:
      "Przy odwiedzinach strony internetowej odkrywane są dane urzydkownika. Te dane będą wyłącznie używane w formie anonimowej w celach statystycznych, aby polepszyć witrynę internetową. IP-adresy urzydkowników przechowywane w formie zamaskowanej. Nie będzie ujawnienia tych danych dla osób trzecich.",
    legal_section4_title: "Odchylające zasady korzystania",
    legal_section4_paragraph:
      "Możliwe są dla pojedyńczych treści odchylające zasady korzystania. W tym przypadku będzie wskazówka w odpowiednim miejscu."
  }
};
