import * as React from "react";
import { FC } from "react";
import { LANG } from "../../localization";
import classes from "./language-switch.module.css";

export interface ILanguageSwitchProps {
  lang: LANG;
}

export const LanguageSwitch: FC = ({ lang }) => {
  console.log({ lang });
  const isGerman = lang === "de-CH";
  const link = isGerman ? "/pl-pl/" : "/de-ch/";
  const text = isGerman ? "Polski" : "Deutsch";
  return (
    <a className={classes.languageSwitch} href={link}>
      {text}
    </a>
  );
};
