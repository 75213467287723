import { LANG } from "../../common/types/localization";

export const gallery = {
  [LANG.deCH]: {
    gallery_title: "Galerie",
    gallery_url_part: "galerie"
  },
  [LANG.plPL]: {
    gallery_title: "Galeria",
    gallery_url_part: "galeria"
  }
};
