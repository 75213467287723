import * as React from "react";
import { FC } from "react";
import * as classnames from "classnames";

import classes from "./fancy-line.module.css";
import spacingsClasses from "../../css/spacings.module.css";

export const FancyLine: FC = () => (
  <hr
    className={classnames(
      classes.fancy,
      spacingsClasses.bottomOuterXxl,
      spacingsClasses.topOuterM
    )}
  />
);
