import * as React from "react";
import { ReactNode } from "react";
import classes from "./heading.module.css";
import * as classnames from "classnames";

export enum HEADING_LEVEL {
  H1 = "1",
  H2 = "2",
  H3 = "3",
  H4 = "4",
  H5 = "5",
  H6 = "6"
}

export enum HEADING_CLASS {
  MAIN = "main",
  WELCOME = "welcome",
  SECTION = "section",
  BOX = "box",
  TEXT = "text"
}

export interface IHeading {
  level: HEADING_LEVEL;
  theme: HEADING_CLASS;
  children: ReactNode;
  className?: string;
}

type IHeadings = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export const Heading = ({
  level = HEADING_LEVEL.H1,
  theme = HEADING_CLASS.MAIN,
  className,
  children
}: IHeading) => {
  const HeadingComponentTag = `h${level}` as IHeadings;
  return (
    <HeadingComponentTag
      className={classnames(classes[theme], className || "")}
    >
      {children}
    </HeadingComponentTag>
  );
};
