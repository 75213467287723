import * as React from "react";
import { FC, ReactNode } from "react";
import { LANG } from "../../common/types/localization";
import { INavigationDataItem } from "./data";
import { Link } from "gatsby";
import * as classnames from "classnames";

interface INavigation {
  lang: LANG;
  theme: IHeaderNavigationTheme;
  items: INavigationDataItem[];
}

interface IHeaderNavigationTheme {
  level1List: string;
  level1Item: string;
  level2List: string;
  level2Item: string;
  active: string;
  partiallyActive: string;
  link: string;
}

interface INavigationItemTheme {
  default: string;
  active: string;
  partiallyActive: string;
}

interface INavigationItem {
  to: string;
  children: ReactNode;
  theme: INavigationItemTheme;
}

export const NavigationItem: FC<INavigationItem> = ({
  to,
  theme,
  children
}) => {
  const getProps = ({ isCurrent, isPartiallyCurrent }) => {
    return {
      className: classnames(theme.default, {
        [theme.active]: isCurrent,
        [theme.partiallyActive]: isPartiallyCurrent
      })
    };
  };

  const internal = /^\/(?!\/)/.test(to) && to.indexOf(".html") === -1;
  if (internal) {
    return <Link to={to} children={children} getProps={getProps} />;
  }
  return (
    <a className={theme.default} href={to}>
      {children}
    </a>
  );
};

export const Navigation: FC<INavigation> = ({ lang, theme, items }) => {
  return (
    <ul className={theme.level1List}>
      {items.map(item => (
        <li key={item.links[lang]}>
          <NavigationItem
            to={item.links[lang]}
            theme={{
              default: theme.level1Item,
              active: theme.active,
              partiallyActive: theme.partiallyActive
            }}
          >
            {item.displayNames[lang]}
          </NavigationItem>
          {item.subs && (
            <ul className={theme.level2List}>
              {item.subs.map(subItem => (
                <li key={subItem.links[lang]}>
                  <NavigationItem
                    theme={{
                      default: theme.level2Item,
                      active: theme.active,
                      partiallyActive: theme.partiallyActive
                    }}
                    to={subItem.links[lang]}
                  >
                    {subItem.displayNames[lang]}
                  </NavigationItem>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};
