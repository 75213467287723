import { LANG } from "../../common/types/localization";

export const cv = {
  [LANG.deCH]: {
    cv_stations: [
      "Norbert Jan Paprotny wurde am 6. Mai 1928 in Zabrze-Pawlow in Polen geboren. Nach Abschluss der Volksschule 1943 (während der Besatzungszeit der Deutschen von 1939 – 1945) wurde ihm als Pole jegliche Weiterbildung untersagt. Dank eines anti Hitler deutschen Malermeisters konnte er während der Kriegsjahre eine Lehre als Dekorationsmaler absolvieren. Um studieren zu dürfen, holte er 1952 die Maturitätsprüfung nach und studierte anschliessend 12 Semester an der Kunstakademie in Krakau mit Diplomabschluss 1959 in den Fachgebieten Malerei, Wandmalerei und Grafik.",
      "Seit 1959 ist er als freischaffender Kunstmaler tätig und ist Mitglied des polnischen Künstlervereins. In verschiedenen Städten Polens und im Ausland nahm er an Gruppen- und Einzelausstellungen teil. 1962 wurde er mit einer Spezial-Auszeichnung der Jury an der internationalen Ausstellung in Monte Carlo gewürdigt.",
      "In den Jahren 1959 – 1971 realisiert er Wandmalereien, Malereien auf keramischen Platten und Mosaiken in über 20 Kirchen und öffentlichen Gebäuden.",
      "Nach neunjähriger Verfolgung durch den Staatssicherheitsdienst verlässt Norbert Paprotny 1972 Polen und wird als Flüchtling in der Schweiz aufgenommen. Bis 1979 arbeitet er in der Glasmalerei H. Mäder in Küsnacht und als Kursleiter in der Klubschule Migros für Malen, Zeichnen, Mosaik und Bauernmalerei. 1979 eröffnet er eine eigene private Kunstschule für Erwachsenenbildung in Zürich unter dem Namen „Kunstschule Atelier N. Paprotny“ und führt diese sehr erfolgreich bis zu seiner Pensionierung 1993. 1987 erhielt er das Bürgerrecht der Stadt Zürich.",
      "Trotz der Beschäftigungen als Kursleiter und Glasmaler führte sein aus Berufung entstandener Arbeitsdrang zu vielen Ausstellungen in der Schweiz und im Ausland. 2003 – 2004 wurde er eingeladen, in vier Museen in Polen auszustellen und erhielt 2003 den Kulturpreis des Präsidenten seiner Heimatstadt Zabrze, der zugleich als eine Art teilweiser Rehabilitation seiner damaligen Flucht aus Polen gilt.",
      "Das Werk von Norbert Jan Paprotny führt in eine Welt der Auseinandersetzung mit dem Alltäglichen, der Empfindungen und Emotionen. Mit einem starken Sinn für innerbildliche, räumliche Wirkungen der Farbe vereint er in spontaner Pinselschrift die figürliche Darstellung mit der gegenstandslosen Flächenkomposition. Oft werden kritische Botschaften vermittelt, ohne die optimistische Wirkung des Bildes zu verlieren.",
      "Da mischen sich Lebens- und Sinnesfreude mit dem Element des Kampfes sowie innerer Beweglichkeit, Denkprozessen und Leidenschaft."
    ]
  },
  [LANG.plPL]: {
    cv_stations: [
      "Norbert Jan Paprotny, urodzony 6. maja 1928 r. w Zabrzu-Pawłowie (Polska). Po ukończeniu szkoły podstawowej w 1943 r. (w czasie okupacji hitlerowskiej 1939 – 1945) zabroniono mu, jako Polakowi, wszelkiego dalszego kształcenia się. Dzięki pomocy dekoracyjnego mistrza malarskiego, Niemca, który okazał się później antyhitlerowcem, mógł rozpocząć naukę malarską, prawie nielegalną. W 1952 [Norbert Jan Paprotny] r. nadrobił maturę i studiował 12 semestrów na Akademii Sztuk Pięknych w Krakowie.",
      "W 1959 r. uzyskał dyplom na wydziale malarstwa, malarstwa ściennego i grafiki. Od 1959 r. pracuje jako twórca i jest członkiem Polskiego Związku Artystów Plastyków. Bierze udział u wystawach ogólnopolskich, okręgowych i indywidualnych. W 1962 r. otrzymuje specjalne wyróżnienie Jury na międzynarodowej wystawie malarstwa w Monte Carlo. W latach 1959 – 1971 realizuje malarstwa ścienne, malarstwa na płytach ceramicznych i mozaiki w około dwudziestu kościołach i budynkach publicznych.",
      "Po dziewięcioletnich prześladowaniach przez Urząd Bezpieczeństwa Norbert Paprotny opuszcza w 1972 r. Polskę i zostaje w Szwajcarii przyjęty jako uchodźca.\n" +
        "Do 1979 roku pracuje w Zurychu jako witrażysta i kierownik pracowni rysunku, malarstwa, mozaiki oraz malarstwa ludowego w Migros Klubschule.",
      "W 1979 roku otwiera własną, prywatną szkołę artystyczną dla dorosłych w Zurychu, pod nazwą „Kunstschule Atelier N. Paprotny” i prowadzi ją z wielkim powodzeniem do wieku emerytalnego w 1993 r. Obywatelstwo miasta Zurych otrzymuje w 1987 roku.",
      "Mimo różnych zajęć nie zatraca siły twórczej wynikającej z powołania i urządza do 2009 roku 17 wystaw w Szwajcarii i innych krajach. W 2003 i 2004 roku został zaproszony do urządzenia wystawy swoich prac w Muzeum Miejskim w Zabrzu, w Muzeum Niepodległości w Warszawie, w Dworze Bella Vita pod Krakowem i w Muzeum Archidiecezjalnym w Katowicach. W 2003 roku został uhonorowany Nagrodą Kultury rodzinnego miasta Zabrze.",
      "Dzieło Norberta Jana Paprotnego prowadzi w świat rozważań nad codziennością, doznań i emocji. Z silnym zmysłem dla głębi, obrazowego – przestrzcennego koloru, łączy spontanicznym pismem pędzla figuratywność i bezprzedniotowość w jedną płaszczyznę kompozycyjną. Często przekazuje krytyczne orędzia, bez utraty optymistycznego dzałania obrazu. Tu miesza się zmysłowo-życiowa radość z żywiołem walki, wewnętrzna ruchliwość, procesy myślenia i namiętności."
    ]
  }
};
