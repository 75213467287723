import { LANG } from "../../common/types/localization";

export interface INavigationDataItem {
  displayNames: {
    [LANG.deCH]: string;
    [LANG.plPL]: string;
  };
  links: {
    [LANG.deCH]: string;
    [LANG.plPL]: string;
  };
  isInSitemap: boolean;
  isInTopNavigation: boolean;
  isInFooterNavigation: boolean;
  subs?: INavigationDataItem[];
}

export const getAllNavigationItems = (): INavigationDataItem[] => [
  {
    displayNames: {
      [LANG.deCH]: "Willkommen",
      [LANG.plPL]: "Zaprasza"
    },
    links: {
      [LANG.deCH]: "/de-ch/willkommen/",
      [LANG.plPL]: "/pl-pl/zaprasza/"
    },
    isInSitemap: true,
    isInTopNavigation: false,
    isInFooterNavigation: false
  },
  {
    displayNames: {
      [LANG.deCH]: "Zur Person",
      [LANG.plPL]: "Odnośnie osoby"
    },
    links: {
      [LANG.deCH]: "/de-ch/zur_person/",
      [LANG.plPL]: "/pl-pl/odnosnie_osoby/"
    },
    isInSitemap: true,
    isInTopNavigation: true,
    isInFooterNavigation: false,
    subs: [
      {
        displayNames: {
          [LANG.deCH]: "Im Atelier",
          [LANG.plPL]: "W pracowni"
        },
        links: {
          [LANG.deCH]: "/de-ch/zur_person/im_atelier/",
          [LANG.plPL]: "/pl-pl/odnosnie_osoby/w_pracowni/"
        },
        isInSitemap: true,
        isInTopNavigation: true,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Lebenslauf",
          [LANG.plPL]: "Życiorys"
        },
        links: {
          [LANG.deCH]: "/de-ch/zur_person/lebenslauf/",
          [LANG.plPL]: "/pl-pl/odnosnie_osoby/zyciorys/"
        },
        isInSitemap: true,
        isInTopNavigation: true,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Kulturpreis der Stadt Zabrze",
          [LANG.plPL]: "Nagroda kultury miasta Zabrze"
        },
        links: {
          [LANG.deCH]: "/de-ch/zur_person/kulturpreis_der_stadt_zabrze/",
          [LANG.plPL]: "/pl-pl/odnosnie_osoby/nagroda_kultury_miasta_zabrze/"
        },
        isInSitemap: true,
        isInTopNavigation: true,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Auszeichnung Monte-Carlo",
          [LANG.plPL]: "Wyróżnienie w Monte Carlo"
        },
        links: {
          [LANG.deCH]: "/de-ch/zur_person/auszeichnung_monte-carlo/",
          [LANG.plPL]: "/pl-pl/odnosnie_osoby/wyroznienie_w_monte-carlo/"
        },
        isInSitemap: true,
        isInTopNavigation: true,
        isInFooterNavigation: false
      }
    ]
  },
  {
    displayNames: {
      [LANG.deCH]: "Galerie",
      [LANG.plPL]: "Galeria"
    },
    links: {
      [LANG.deCH]: "/de-ch/galerie/index.html",
      [LANG.plPL]: "/pl-pl/galeria/index.html"
    },
    isInSitemap: true,
    isInTopNavigation: true,
    isInFooterNavigation: false,
    subs: [
      {
        displayNames: {
          [LANG.deCH]: "Ausstellungen",
          [LANG.plPL]: "Wystawy"
        },
        links: {
          [LANG.deCH]: "/de-ch/galerie/ausstellungen/",
          [LANG.plPL]: "/pl-pl/galeria/wystawy/"
        },
        isInSitemap: true,
        isInTopNavigation: true,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Chorzów, St. Floriankirche",
          [LANG.plPL]: "Chorzów, Kościół św. Floriana"
        },
        links: {
          [LANG.deCH]: "/de-ch/galerie/chorzow.html",
          [LANG.plPL]: "/pl-pl/galeria/chorzow.html"
        },
        isInSitemap: true,
        isInTopNavigation: false,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Tarnów, Kapelle des Herz Jesu-Ordens",
          [LANG.plPL]: "Tarnów, Kaplica Księży Sercanów"
        },
        links: {
          [LANG.deCH]: "/de-ch/galerie/tarnow.html",
          [LANG.plPL]: "/pl-pl/galeria/tarnow.html"
        },
        isInSitemap: true,
        isInTopNavigation: false,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Świerklany, Pfarrkirche",
          [LANG.plPL]: "Świerklany, Kościół Parafialny"
        },
        links: {
          [LANG.deCH]: "/de-ch/galerie/swierklany.html",
          [LANG.plPL]: "/pl-pl/galeria/swierklany.html"
        },
        isInSitemap: true,
        isInTopNavigation: false,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Zabrze, Teatr Nowy",
          [LANG.plPL]: "Zabrze, Teatr Nowy"
        },
        links: {
          [LANG.deCH]: "/de-ch/galerie/zabrze_teatr.html",
          [LANG.plPL]: "/pl-pl/galeria/zabrze_teatr.html"
        },
        isInSitemap: true,
        isInTopNavigation: false,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Hażlach, Pfarrkirche – Kreuzwegstationen",
          [LANG.plPL]: "Hażlach, Kościół Parafialny – stacje drogi krzyżowej"
        },
        links: {
          [LANG.deCH]: "/de-ch/galerie/hazlach.html",
          [LANG.plPL]: "/pl-pl/galeria/hazlach.html"
        },
        isInSitemap: true,
        isInTopNavigation: false,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Zabrze, St. Annakirche",
          [LANG.plPL]: "Zabrze, Kościół św. Anny"
        },
        links: {
          [LANG.deCH]: "/de-ch/galerie/zabrze_anna.html",
          [LANG.plPL]: "/pl-pl/galeria/zabrze_anna.html"
        },
        isInSitemap: true,
        isInTopNavigation: false,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Rybnik, Kirche der trauernden Mutter Gottes",
          [LANG.plPL]: "Rybnik"
        },
        links: {
          [LANG.deCH]: "/de-ch/galerie/rybnik.html",
          [LANG.plPL]: "/pl-pl/galeria/rybnik.html"
        },
        isInSitemap: true,
        isInTopNavigation: false,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Jurków an der Nida, Pfarrkirche",
          [LANG.plPL]: "Jurków"
        },
        links: {
          [LANG.deCH]: "/de-ch/galerie/jurkow.html",
          [LANG.plPL]: "/pl-pl/galeria/jurkow.html"
        },
        isInSitemap: true,
        isInTopNavigation: false,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Panewniki, Franziskaner Kirche",
          [LANG.plPL]: "Panewniki"
        },
        links: {
          [LANG.deCH]: "/de-ch/galerie/panewniki.html",
          [LANG.plPL]: "/pl-pl/galeria/panewniki.html"
        },
        isInSitemap: true,
        isInTopNavigation: false,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Bujaków, Kirche St. Nikolaus",
          [LANG.plPL]: "Bujaków"
        },
        links: {
          [LANG.deCH]: "/de-ch/galerie/bujakow.html",
          [LANG.plPL]: "/pl-pl/galeria/bujakow.html"
        },
        isInSitemap: true,
        isInTopNavigation: false,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Mikołów, Salvatorianerkirche",
          [LANG.plPL]: "Mikołów"
        },
        links: {
          [LANG.deCH]: "/de-ch/galerie/mikolow.html",
          [LANG.plPL]: "/pl-pl/galeria/mikolow.html"
        },
        isInSitemap: true,
        isInTopNavigation: false,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Zabrze St. Josefskirche",
          [LANG.plPL]: "Zabzre"
        },
        links: {
          [LANG.deCH]: "/de-ch/galerie/zabrze_josef.html",
          [LANG.plPL]: "/pl-pl/galeria/zabrze_josef.html"
        },
        isInSitemap: true,
        isInTopNavigation: false,
        isInFooterNavigation: false
      }
    ]
  },
  {
    displayNames: {
      [LANG.deCH]: "Wandmalereien",
      [LANG.plPL]: "Malarstwo ścienne"
    },
    links: {
      [LANG.deCH]: "/de-ch/wandmalereien/",
      [LANG.plPL]: "/pl-pl/malarstwo_scienne/"
    },
    isInSitemap: true,
    isInTopNavigation: true,
    isInFooterNavigation: false
  },
  {
    displayNames: {
      [LANG.deCH]: "Beiträge",
      [LANG.plPL]: "Wkładki"
    },
    links: {
      [LANG.deCH]: "/de-ch/beitraege/",
      [LANG.plPL]: "/pl-pl/wkladki/"
    },
    isInSitemap: true,
    isInTopNavigation: true,
    isInFooterNavigation: false,
    subs: [
      {
        displayNames: {
          [LANG.deCH]: "Einige Worte über Wandmalereien",
          [LANG.plPL]: "Kilka słów o malarstwie ściennym"
        },
        links: {
          [LANG.deCH]: "/de-ch/beitraege/einige_worte_ueber_wandmalereien/",
          [LANG.plPL]: "/pl-pl/wkladki/kilka_slow_o_malarstwie_sciennym/"
        },
        isInSitemap: true,
        isInTopNavigation: true,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Behauptung",
          [LANG.plPL]: "Twierdzenie"
        },
        links: {
          [LANG.deCH]: "/de-ch/beitraege/behauptung/",
          [LANG.plPL]: "/pl-pl/wkladki/twierdzenie/"
        },
        isInSitemap: true,
        isInTopNavigation: true,
        isInFooterNavigation: false
      }
    ]
  },
  {
    displayNames: {
      [LANG.deCH]: "Aktuelles",
      [LANG.plPL]: "Aktualności"
    },
    links: {
      [LANG.deCH]: "/de-ch/aktuelles/",
      [LANG.plPL]: "/pl-pl/aktualnosci/"
    },
    isInSitemap: true,
    isInTopNavigation: true,
    isInFooterNavigation: false,
    subs: [
      {
        displayNames: {
          [LANG.deCH]: "Todesanzeige",
          [LANG.plPL]: "Pośmiertny"
        },
        links: {
          [LANG.deCH]: "/de-ch/aktuelles/todesanzeige/",
          [LANG.plPL]: "/pl-pl/aktualnosci/posmiertny/"
        },
        isInSitemap: true,
        isInTopNavigation: true,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Beflügeltes Leiden (Buch)",
          [LANG.plPL]: "Uskrzydlone cierpienie (książka)"
        },
        links: {
          [LANG.deCH]: "/de-ch/aktuelles/befluegeltes_leiden_buch/",
          [LANG.plPL]: "/pl-pl/aktualnosci/uskrzydlone_cierpienie_ksiazka/"
        },
        isInSitemap: true,
        isInTopNavigation: true,
        isInFooterNavigation: false
      },
      {
        displayNames: {
          [LANG.deCH]: "Reise durch Polen",
          [LANG.plPL]: "Z podróży po Polsce"
        },
        links: {
          [LANG.deCH]: "/de-ch/aktuelles/reise_durch_polen/",
          [LANG.plPL]: "/pl-pl/aktualnosci/z_podrozy_po_polsce/"
        },
        isInSitemap: true,
        isInTopNavigation: true,
        isInFooterNavigation: false
      }
    ]
  },
  {
    displayNames: {
      [LANG.deCH]: "Rechtliche Hinweise",
      [LANG.plPL]: "Wskazówki prawne"
    },
    links: {
      [LANG.deCH]: "/de-ch/rechtliche_hinweise/",
      [LANG.plPL]: "/pl-pl/wskazowki_prawne/"
    },
    isInSitemap: true,
    isInTopNavigation: false,
    isInFooterNavigation: true
  },
  {
    displayNames: {
      [LANG.deCH]: "Kontakt",
      [LANG.plPL]: "Kontakt"
    },
    links: {
      [LANG.deCH]: "/de-ch/kontakt/",
      [LANG.plPL]: "/pl-pl/kontakt/"
    },
    isInSitemap: true,
    isInTopNavigation: false,
    isInFooterNavigation: true
  },
  {
    displayNames: {
      [LANG.deCH]: "Sitemap",
      [LANG.plPL]: "Mapa strony"
    },
    links: {
      [LANG.deCH]: "/de-ch/sitemap/",
      [LANG.plPL]: "/pl-pl/mapa_strony/"
    },
    isInSitemap: true,
    isInTopNavigation: false,
    isInFooterNavigation: true
  }
];

const getFiltered = filterProp => {
  const allNavigationItems = getAllNavigationItems();
  const filteredItems = allNavigationItems.filter(item => item[filterProp]);
  filteredItems.forEach(item => {
    item.subs = item.subs
      ? item.subs.filter(subItem => subItem[filterProp])
      : item.subs;
  });
  return filteredItems;
};

export const headerNavigationItems = getFiltered("isInTopNavigation");

export const footerNavigationItems = getFiltered("isInFooterNavigation");

export const sitemapItems = getFiltered("isInSitemap");
