import { LANG } from "../../common/types/localization";

export const sitemap = {
  [LANG.deCH]: {
    sitemap_title: "Sitemap"
  },
  [LANG.plPL]: {
    sitemap_title: "Mapa strony"
  }
};
