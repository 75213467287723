import { LANG } from "../../common/types/localization";

export const welcome = {
  [LANG.deCH]: {
    welcome_painting_alttext: "Bild von Norbert Jan Paprotny",
    welcome_signature_alttext:
      "animierte Unterschrift von Norbert Jan Paprotny",
    welcome_short_signature_alttext: "Kürzel von Norbert Jan Paprotny"
  },
  [LANG.plPL]: {
    welcome_painting_alttext: "Zdjęcie Norberta Jana Paprotnego",
    welcome_signature_alttext: "animowany podpis Norberta Jana Paprotnego",
    welcome_short_signature_alttext: "Skrót Norbert Jan Paprotny"
  }
};
