import { LANG } from "../../common/types/localization";

export const contact = {
  [LANG.deCH]: {
    contact_heading: "Kontakt",
    contact_email_disclaimer_line1:
      "Um Spam zu vermeiden, veröffentlichen wir die E-Mail Adresse nicht in Klartext.",
    contact_email_disclaimer_line2:
      "Ersetzen sie in der E-Mail Adresse oben {at} mit einem '@' und {punkt} mit einem '.'",
    contact_support_part1: "Die Website wurde von gwildu webdesign mit",
    contact_support_part2: "erstellt.",
    contact_support_part3:
      "Wenn Sie technische Probleme mit der Website haben, benutzen Sie bitte die Support Adresse:"
  },
  [LANG.plPL]: {
    contact_heading: "Kontakt",
    contact_email_disclaimer_line1:
      "By unikać Spam, adres E-Mail nie publikujemy gdyż jest zakodowany.",
    contact_email_disclaimer_line2:
      "Proszę zamienić w adresie E-Mail ugóry {at} z '@' a {kropkę} z '.'",
    contact_support_part1:
      "Witryna internetowa została przez gwildu webdesign z",
    contact_support_part2: "wykonana.",
    contact_support_part3:
      "Jeżeli powstaną probleme z witryną internetową, proszę użyć adresu Support:  "
  }
};
