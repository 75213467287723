import { LANG } from "../../common/types/localization";

export const awardZabrze = {
  [LANG.deCH]: {
    awardZabrze_title: "Zum Kulturpreis",
    awardZabrze_text1:
      "Nach dem Verlassen meines Heimatlandes Polen im Februar 1972 wegen neunjähriger Verfolgung durch den damaligen Staatssicherheitsdienst, hatte man ein Plakat im Stadthaus von Zabrze mit der Inschrift aufgehängt:",
    awardZabrze_text2:
      "„Der bekannte Kunstmaler Norbert Paprotny hat unser Land illegal verlassen und ist somit zum Landesverräter geworden.”",
    awardZabrze_text3:
      "Viele Jahre sind vergangen, inzwischen ist eine Wende in der Staatsform 1989 zu Stande gekommen und das Land hat sich langsam von den Zwängen der Volksrepublik erholt. An meinen Namen hatte man sich erinnert nach meiner grossen Ausstellung im Polenmuseum im Schloss Rapperswil im Jahre 2000. Ausstellungen in drei Museen und einem Kulturzentrum in Polen wurden mir angeboten. Die erste Ausstellung im Jahr 2003 hat im Städtischen Museum meiner Heimatstadt Zabrze stattgefunden. Darauf folgten die Ausstellungen im Museum Der Unabhängigkeit in Warschau, im Kulturzentrum Bella Vita bei Krakau und im Archidiözesal-Museum in Katowice. Nach den Ausstellungen wurde an einer Sitzung des Stadtrates der Stadt Zabrze beschlossen, mir den Kulturpreis des Präsidenten der Stadt Zabrze aufgrund der hohen künstlerischen Werte, des starken Persönlichkeitscharakters und der Treue zur künstlerischen Berufung, trotz aller Widerwärtigkeiten des Schicksals, zu verleihen.",
    awardZabrze_image: "/award-zabrze/kulturpreis.png",
    awardZabrze_image_alt: "Brief Kulturpreis - Übersetzung aus dem Polnischen"
  },
  [LANG.plPL]: {
    awardZabrze_title: "Nagroda Kultury",
    awardZabrze_text1:
      "Po opusczeniu mojej ojczyzny w lutym 1972 r. z powodu dziewięcioletnich prześladowań przez ówczesny Urząd Bespieczństwa, wywieszono plakat w magistracie miasta Zabrze o następującej treści:",
    awardZabrze_text2:
      "„Znany artysta malarz Norbert Paprotny opuścił nasz kraj nielegalnie i tymsamym stał się zdrajcą narodu.”",
    awardZabrze_text3:
      "Minęło wiele lat i w międzyczasie zaistniała zmiana w ustroju państwa w 1989 r. i kraj powoli uwolnił się z przymusów Republiki Ludowej. O moim nazwisku przypomniano sobie po mojej wielkiej wystawie w Muzeum Polskim na zamku w Rapperswil w 2000 roku. Zaproponowano mi wystawę w trzech muzeach i w centrum kulturalnym w Polsce. Pierwsza wystawa miała miejsce w 2003 roku w muzeum miejskim rodzinnego miasta Zabrze. Po niej nastąpiły wystawy w Muzeum Niepodległości w Warszawie, w centrum kultury Bella Vita pod Krakowem i w Museum Archidiezesjalnym w Katowicach. Po tych wystawach na posiedzeniu Rady Miejskiej w Zabrzu, postanowiono przyznać mi Nagrodę Prezydenta Miasta Zabrze w dziedzinie Kultury, za wysokie walory artystyczne, siły charakteru osobowości i wierności powołaniu artystycznemu – wbrew wszelkim przeciwnością losu.",
    awardZabrze_image: "/award-zabrze/nagroda-kultury.gif",
    awardZabrze_image_alt: "nagroda kultury"
  }
};
