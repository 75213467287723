import { LANG } from "../../common/types/localization";

export const poland = {
  [LANG.deCH]: {
    poland_title: "Aktuelles von unserer Reise durch Polen",
    poland_text_1:
      "Unsere Schweizerfreunde, mit denen wir im August 2010 Polen bereist haben, zeigten Interesse an meinen Wandmalereien, die ich in den Jahren 1959 bis 1971 in den Kirchen Schlesiens ausgeführt habe.",
    poland_text_2:
      "Da meine sakralen Wandmalereien in Form von Mosaiken und Malerei auf keramischen Platten bis heute im originalen Zustand geblieben sind, habe ich beschlossen, meinen Gästen die 100m² grosse Wandmalerei auf keramischen Platten in der St. Florian Kirche in der Stadt Chorzów zu zeigen. (siehe Film Chorzów im Artikel über Wandmalerei).",
    poland_text_3:
      "Doch die Kirche war für Gläubige und Besucher geschlossen. Unverständlich für meine Freunde aus Zürich. Um Ihnen den Besuch der Kirche zu ermöglichen, begab ich mich ins Pfarrhaus mit der Bitte, uns den Besuch der Kirche und die Besichtigung meiner Wandmalerei zu ermöglichen. Der Priester K. Pyszny erläuterte mir, dass die Erfüllung meiner Bitte nicht möglich sei, weil der Pfarrer abwesend und der Schlüssel der Kirche im Besitz des Küsters ist.",
    poland_text_4:
      "Um die peinliche Situation zu retten, stellte ich mich dem Priester K. Pyszny mit meiner Visitenkarte als Kunstmaler und Autor der Wandmalerei dieser Kirche vor, mit der Bitte, mich mein Werk noch einmal sehen zu lassen und meinen Schweizer Freunden den Anblick auf die Dauerhaftigkeit der glasierten Sakralmalerei zu gewähren.",
    poland_text_5: "",
    poland_label: "St. Florian Kirche",
    poland_text_6:
      "Die Antwort auf meine Identifikationsvorstellung und meine Bitte lautete folgendermassen: „Da sollte man sich vier Stunden vorher telefonisch anmelden“. Auf meine Frage, wie ein Reisender aus dem Ausland im Auto die Telefonnummer der Pfarrei dieser Kirche ausfindig machen kann um sich vier Stunden vorher anzumelden, lautete die Antwort des Priester K. Pyszny: „Im Internet kann man uns finden“.",
    poland_text_7:
      "Welch kluge Belehrung der dummen Ausländer!! Bravo Herr Priester K. PYSZNY! (Übersetzt bedeutet der Name hochmütig oder überheblich.)",
    poland_text_8:
      "Mit meinen Schweizer Freunden stand ich kompromittiert als Künstler und als Autor des Kunstwerkes dieser Kirche, dem man mit 82 Jahren den Einlass in die Kirche verweigert hat, um sein Werk vielleicht ein letztes Mal zu sehen. Der Seelsorgepriester K. Pyszny hat ein deprimierendes und beschämendes Beispiel der von mir Jahrzehnte lang gelobten polnischen Gastfreundschaft den Ausländern geliefert, welches mich sehr beschämte und die Scham ist in mir bis heute.",
    poland_date: "September 2010"
  },
  [LANG.plPL]: {
    poland_title: "Aktualności z podróży po Polsce",
    poland_text_1:
      "Przyjaciele szwajcarscy, z którymi zwiedzaliśmy Polskę w sierpniu 2010 r., byli zainteresowani moimi pracami z dziedziny malarstwa ściennego w kościołach na Śląsku z lat 1959 – 1971.",
    poland_text_2:
      'Ponieważ do tej pory utrzymały się tylko malarstwa w postaci mozaik i malarstwa na płytach ceramicznych w stanie pierwotnym, postanowiłem je pokazać moim przyjaciołom. Pojechałem z nimi w dniu 12 sierpnia o godz. 11°° do kościoła św. Floriana w Chorzowie (patrz film Chorzów w artykule "Malarstwo ścienne" oraz artykuł „Nowy Przegląd Chorzowski” w rubryce Teksty), lecz kościół zastaliśmy niestety zamknięty, tak dla wiernych jak i dla zwiedzających.',
    poland_text_3:
      "Niepojęty fakt dla gości z Zurychu. By umożliwić im zwiedzenie tego kościoła i zobaczenia mojego malarstwa ściennego udałem się na plebanię, prosząc ks. K. Pysznego o umożliwienie nam wejścia do kościoła.",
    poland_text_4:
      "Ks. K. Pyszny oświadczył mi, że spełnienie mojej prośby nie jest możliwe, „ponieważ ks. proboszcz jest nieobecny, a klucz do kościoła jest w posiadaniu kościelnego”.",
    poland_text_5:
      "Chcąc złagodzić nieprzyjemną sytuację, przedstawiłem się księdzu jako artysta i autor wykonanego dzieła malarskiego w ich kościele. Przyjechałem ze Szwajcarii, by po latach zobaczyć moje dzieło i Szwajcarom pokazać stan trwałości malarstwa sakralnego w technice glazurowej.",
    poland_label: "Kościół św. Floriana",
    poland_text_6:
      "Odpowiedź na moją identyfikację i prośbę była następująca: „To trzeba było się zgłosić telefonicznie cztery godziny wcześniej”. Na zapytanie, w jaki sposób zagraniczny podróżujący w samochodzie znaleźć może numer telefonu plebanii tego kościoła, by zgłosić swoją wizytę cztery godziny wcześniej, odpowiedź ks. K. Pysznego brzmiała: „W Internecie można nas znaleść”. Mądra odpowiedź - prawda? Brawo księże PYSZNY!",
    poland_text_7:
      "Z moimi szwajcarskimi przyjaciółmi stałem skompromitowany jako artysta i autor tego dzieła, którego nie wpuszczono do kościoła, by (mając 82 lata) może po raz ostatni spojrzeć na swój dorobek.",
    poland_text_8:
      "Duszpasterz ks. K. PYSZNY dał gościom zagranicznym kompromitujący i hańbiący przykład od dziesiątków lat zachwalanej przeze mnie polskiej gościnności. Poczułem się wobec moich przyjaciół bardzo zawstydzony i ten wstyd trwa we mnie do dzisiaj.",
    poland_date: "Wrzesień 2010"
  }
};
