import { LANG } from "../../common/types/localization";

export const claim = {
  [LANG.deCH]: {
    claim_title: "Attraktives Geschwätz umkreist die Welt",
    claim_teaser_1:
      "Ein Unwissender „Gelehrter“ hat eine Behauptung in Umlauf gebracht, die von andern übernommen und seit dutzenden von Jahren in Vorträgen, Büchern, Zeitschriften und durch Reiseführer in der Welt verbreitet werden.",
    claim_teaser_2:
      "Michelangelo hat die Sixtinische Kapelle auf dem Rücken liegend zwei Jahre lang gemalt und die Farbe tropfte ihm ins Gesicht!",
    claim_teaser_3:
      "Mit meinem kurzen Aufsatz aus meiner langjährigen Erfahrung in der Wandmalerei, werde ich versuchen die Behauptung zu widerlegen.",
    claim_text_1:
      "Um eine Wandmalerei an einem Deckengewölbe ausführen zu können, muss ein Gerüst aufgebaut werden. Das Gerüst hat an der Wand Laufstege, die in einem Abstand von 1.80 m bis 1.90 m in der Höhe, stufenweise bis zu der Decke aufgebaut werden. Der Abstand ist notwendig, damit der Ausführende mit ausgestrecktem Arm und dem Werkzeug die nächste Stufe erreichen kann. Das Deckengewölbe wird ganz eingerüstet und mit Brettern ausgelegt, sodass eine Bodenfläche entsteht, auf der sich Handwerker (Maurer, Maler, Gehilfen) und der Künstler frei bewegen können, um ihre Arbeit auszuführen. Der Abstand von der Bodenfläche bis zur Decke entspricht der Grösse eines erwachsenen Menschen mit ausgestrecktem Arm und dem Werkzeug in der Hand (Reibbrett beim Maurer, Pinsel beim Maler). Auf dem mit Brettern ausgelegten Boden befinden sich für die Maurer mehrere Gefässe mit Mörtel und Kübel mit Wasser nebst Werkzeugen. Für den Maler dutzende Gefässe mit in Wasser angerührten Pigmenten, eine grosse Schale in der die Farben gemischt werden, grosse Kübel mit Wasser und dutzende Pinsel verschiedener Art.",
    claim_text_2:
      "Für die Alfresko-Technik (in der die Sixtinische Kapelle gemalt wurde) werden mindestens drei Schichten Mörtel stufenweise mit Abstand eines Tages aufgetragen. Für die erste Mörtelschicht wird grober Sand mit gelöschtem Kalk vermischt und aufgeworfen. Nach dem Antrocknen wird die zweite Schicht mit feinerem Sandgemisch aufgetragen. In der letzten sehr dünnen Schicht wird sehr feiner Sand, manchmal auch Marmormehl verwendet. Die Maurer sind mit ihrer Arbeit dem Malenden drei bis vier Tagewerke wegen der Antrocknung der Mörtelschichten voraus. Die letzte dünne Mörtelschicht wird am Vortag des Bemalens nur so gross aufgetragen wie der malende Künstler fähig ist, an einem Tag auszumalen. Die nicht bemalte letzte Schicht wird von Maurern abgetragen und eine frische Schicht für den nächsten Tag aufgetragen. Das Tagewerk der Maurer und des Künstlers wiederholt sich, bis die ganze zu bemalende Fläche fertiggestellt wird.",
    claim_text_3:
      "Nach dem Antrocknen der letzten Schicht wird die Zeichnung, die vorher auf Papier an einer grossen Wand oder im Atelier angefertigt wurde, um die Proportionen der überdimensionalen Figuren festzuhalten, mit einer Nadel im Abstand ca. 1 cm durchgestochen und mit einem Pausbeutel (ein Stoffballen mit Kohlenstaub gefüllt) durchgestaubt. Die Zeichnung erscheint als Staubpunktezeichnung auf dem feuchten Putz und wird mit Pinsel und Farbe nachgezogen. Erst dann fängt der Künstler mit Malen sein Tagewerk an. Das ist in grosser Abkürzung der Ablauf der Arbeiten vieler Männer auf dem Gerüst zu fast gleicher Zeit.",
    claim_text_4:
      "Das Licht: Das Gerüst und der mit Brettern belegte Boden des Deckengewölbes schirmen das Licht aus den tiefer gelegenen Fenstern so ab, dass im Bereich der auszuführenden Malerei eine Dunkelheit herrscht. Die Sixtinische Kapelle wurde im 16. Jh. gemalt. Das elektrische Licht wurde erst Ende des 19. Jh. erfunden.",
    claim_text_5:
      "Welche Lichtquellen haben die Handwerker und Michelangelo zur Verfügung? Eine Fackelbeleuchtung ist wegen der starken Russbildung an der Decke auszuschliessen. Geblieben sind Wachs- oder Öllampen die das Gewölbe ausleuchten. Die Farben in den Töpfen waren nur durch die Reflexion der ausgeleuchteten Decke zu erkennen. Liegend beträgt der Abstand vom Bretterboden zur Decke: ein ausgestreckter Arm und die Pinsellänge. Das beträgt ca. 80 cm. Aus der liegenden Position ist es nicht möglich den richtigen Pinsel zu finden und die Farbe in den Töpfen einzusehen, weil sich diese auf der Ebene des Kopfes befinden. Ausserdem ist das Blickfeld auf die bemalte Fläche zum Minimalen beschränkt. Die flüssige Farbe läuft dem Pinselstil entlang zur Handfläche, den ausgestreckten Arm herunter, stehend oder liegend, und nach vielen Stunden Arbeit machen sich Spuren bis zum Schuhwerk bemerkbar. Das Tropfen der Farbe entsteht beim Abschütteln des zu nass gewordenen Pinselstils.",
    claim_text_6: "",
    claim_text_7: "",
    claim_label_1:
      "... er malte zwei Jahre auf dem Rücken liegend und die Farbe tropfte ihm ins Gesicht",
    claim_label_2:
      "So einfach ist die Malerei in der Vorstellung des „Gelehrten“!",
    claim_text_8:
      "In der Phantasie des Urhebers und der Verfechter dieser Behauptung entstand ein Bild eines einsamen, auf dem Gerüst liegenden Michelangelo, sein Gemälde pinselnd und der grösste lästige Störfaktor war das Tropfen der Farbe ins Gesicht. Dass in dieser Phantasie-Vorstellung die Maurer, die Gehilfen und der Künstler selbst Torturen ausgesetzt würden, zwei Jahre lang auf allen Vieren in der Schwüle unter dem 80 cm niedrigen Gewölbe kriechen zu müssen, um in der unbequemsten Position ihre schwere Maurer- und Malerarbeit auszuführen, - kommt ihnen nicht in den Sinn.",
    claim_text_9:
      "Es wäre eine Unterstellung zu vermuten, dass der geniale Michelangelo Buonarotti (1475 – 1564), Bildhauer, Maler und Architekt, der die Kuppel des St. Peter in Rom entworfen und gebaut hatte, unfähig war, sich einen langjährigen, geeigneten Arbeitsplatz einzurichten."
  },
  [LANG.plPL]: {
    claim_title: "Atraktywna plotka obiega świat",
    claim_teaser_1:
      "Pewien bezwiedny „uczony“ rozpowszechnił twierdzenie, , które podchwycone przez innych, od dziesiątek lat w wykładach, książkach, czasopismach i przez przewodników turystycznych powielane są po świecie.",
    claim_teaser_2:
      "Michelangelo malował kaplicę Syxstyńską przez dwa lata na plecach leżąc, a farba spadała mu kroplami na twarz",
    claim_teaser_3:
      "W mojej krótkiej rozprawie, powołując się na własne długoletnie doświadczenia w dziedzinie malarstwa ściennego, postaram się to twierdzenie podważyć.",
    claim_text_1:
      "Chcąc wykonać malarstwo ścienne na sklepieniu, trzeba zbudować rusztowanie. Rusztowanie przy ścianach podzielone chodnikami w odstępach 1.80 m do 1.90 m wyskości stopniowo rozbudowane jest pod samo sklepienie. Podany odstęp jest konieczny,by wykonujący pracę, z wysuniętym ramieniem, trzymając narzędzie pracy dosięgał następny podest. Samo sklepienie jest całkowicie zarusztowane i wyłożone deskami tak, by powstała płaszczyzna podłogi, na której swobodnie poruszać się mogą rzemieślnicy (murarze, malarze, pomocnicy) oraz arysta, by móc wykonać zamierzone prace. Odległość od podłogi do sklepienia odpowiada wielkości dorosłego człowieka z wyciągniętym ramieniem i narzędziem pracy w ręce.",
    claim_text_2:
      "Na wyłożonej deskami podłodze znajdują się dla murarzy liczne naczynia z zaprawą murarską, zbiorniki z wodą, oraz narzędzia. Dla malarza dziesiątki kubków z rozpuszczonym w wodzie pigmentem, duża patera w której mieszane będą kolory, duże kubły z wodą i tuziny różnorakich pędzli.",
    claim_text_3:
      "W technice alfresko (w której malowana została kaplica syxstyńska) narzuca się stopniowo conajmniej trzy warstwy zaprawy murarskiej w odstępach jednego dnia. Pierwsza warstwa narzutu, jest mieszaniną gurboziarnistego piasku z gaszonym wapnem. Druga warstwa z piasku średnioziarnistego z wapnem narzucana jest na lekko podeschniętą warstwę pierszą. Ostatnia warstwa z mieszaniną mialkiego piasku, albo mąki marmurowej z wapnem jest cienko nakładana. Prace murarskie wyprzedzają prace malarskie o trzy do czterech dni z powodu konieczności podsychania narzuconych warstw zaprawy.",
    claim_text_4:
      "Ostatnia cienka warstwa zaprawy narzucana jest jeden dzień przed rozpoczęciem malowidła i tylko w rozmiarach, które malujący artysta jest w stanie pomalować w ciągu jednego dnia. Niepomalowana ostatnia warstwa zostaje przez murarzy usumięta i świeżą warstwą na następny dzień pracy uzupełniona. Prace dzienne murarzy i artysty powtarzają się do zakończenia wykonanego malarstwa.",
    claim_text_5:
      "Rysunek, który wcześniej wykonany został na papierze na dużej ścianie albo w pracowni z powodu lepszego opanowania proporcji ogromnych figur, podziurkowany jest igłą w odstępach ca. 1 cm, przepruszony woreczkiem wypełniony prochem węglowym na podeschniętą ostatnią warstwę zaprawy. Rysunek wyłania się kropkowany na wilgotnej ścianie tynku i zostaje pędzlem i farbą uzupełniony. Od tego momentu artysta rozpoczyna malarską pracę dzienną. Tak przedstawia się w wielkim skrócie przebieg prac wielu mężczyzn na rusztowaniu, prawie w tym samym czasie.",
    claim_text_6:
      "Światło: Rusztowanie i podłoga pod sklepieniem zasłaniają światło z niżej położonych okien tak mocno, że w obrębie wykonania malarstwa panuje półmrok. Kaplica syxstyńska malowana była w szesnastym wieku. Światło elektryczne wynalezione zostało pod koniec dziewiętnastego wieku. Jakie źródła światła mieli rzemieślnicy i Michelangelo do dypozycji? Oświetlenie pochodnią można wykluczyć z powodu silnego osadzenia sklepienia. Pozostaje oświetlenie woskowe albo lampa olejna oświetlająca sklepienie. Kolory w kubkach można było rozróżnić tylko przez odbicie światła oświetlonego sklepienia. Leżąc, odległość od podłogi do sklepienia ma wymiar wyciągniętego ramienia i długości pędzla. To wynosi ca 80 cm. Z pozycji leżącej nikt nie jest w stanie rozróżnić koloru w kubkach znajdujących się na poziomie głowy, ani znaleść odpowiedni pędzel nie mówiąc o mieszaniu kolorów. Pozatym pole widzenia malowanej płaszczyzny ograniczone zostaje do minimum.",
    claim_text_7:
      "Rzadka farba spływa po pędzlu do ręki i po ramieniu w dół niezależnie czy się stoi albo leżey, a po kilkugodzinnej pracy odczuwa się ślady farbe w butach. Krople farby na twarzy spowodowane są strzęśnięciem nadmiaru farby z człona pędzla.",
    claim_label_1:
      "...malował dwa lata na plecach leżęc, a farba spadała mu kroplami na twarz",
    claim_label_2: "Tak proste jest malarstwo w fantazji „uczonego”!",
    claim_text_8:
      "W wyobraźni inicjatora i orędowników tego twierdzenia powstał obraz samotnie na rusztowaniu leżącego Michelangela, swoje malowidło pędzlując, a największą uciążliwą przeszkodą było kapanie farby na twarz.",
    claim_text_9:
      "Że w ich fantazyjnej wyobraźni, murarze, pomocnicy i sam artysta narażeni byliby na tortury, dwa lata pod dusznym, 80 cm niskim sklepieniem, na czworakach pełzając, w najbardziej niewygodnej pozycji ich ciężką pracę murarską i malarską wykonując - nie wspada im na myśl. Insynuacją było by przypuszczać, że genialny Michelangelo Buonarotti (1475 – 1564) rzeźbiarz, malarz i architekt, który zaprojektował i budował kopułę katedry św. Piotra w Rzymie, niezdolny był urządzić sobie odpowiedne, długoletne miejsce pracy."
  }
};
