import { LANG } from "../../common/types/localization";
import { GALLERY_NAME } from "../../entrypoints/galery";

export const wallpaintings = {
  [LANG.deCH]: {
    wallpaintings_title:
      "Beispiele der Wandmalerei die in den Jahren 1959 - 1971 in Polen entstanden sind",
    wallpaintings_chorzow_title: "Chorzów, St. Floriankirche",
    wallpaintings_chorzow_text: "Malerei auf keramischen Platten - ",
    wallpaintings_tarnow_title: "Tarnów, Kapelle des Herz Jesu-Ordens",
    wallpaintings_tarnow_text: "Malerei auf keramischen Platten",
    wallpaintings_swierklany_title: "Świerklany, Pfarrkirche",
    wallpaintings_swierklany_text:
      "Das letzte Abendmahl und Fragment der Kreuzwegstationen. Malerei auf keramischen Platten",
    wallpaintings_zabrze_teatr_title: "Zabrze, Teatr Nowy",
    wallpaintings_zabrze_teatr_text:
      "Mosaik in Verbindung mit Malerei auf keramischen Platten – 14m x 4.5m",
    wallpaintings_hazlach_title: "Hażlach, Pfarrkirche – Kreuzwegstationen",
    wallpaintings_zabrze_anna_title: "Zabrze, St. Annakirche",
    wallpaintings_zabrze_anna_text:
      'Originalzustand. (Die einzige Dokumentation) Heute ist die Malerei "erneuert".',
    wallpaintings_rybnik_title: "Rybnik, Kirche der trauernden Mutter Gottes",
    wallpaintings_rybnik_text: "Ein Beispiel der industriellen Verschmutzung.",
    wallpaintings_jurkow_title: "Jurków an der Nida, Pfarrkirche",
    wallpaintings_jurkow_text: "Ein Fragment der Malerei.",
    wallpaintings_panewniki_title: "Panewniki, Franziskaner Kirche",
    wallpaintings_panewniki_text:
      'Die Malerei wurde "erneuert". Es gibt keine Originaldokumentation.',
    wallpaintings_bujakow_title: "Bujaków, Kirche St. Nikolaus",
    wallpaintings_bujakow_text:
      "Fragment der Wandmalerei. Die Wandmalerei der Kirche wurde 1978 zerstört. Ex existieren keine andere Dokumentationen",
    wallpaintings_mikolow_title: "Mikołów, Salvatorianerkirche",
    wallpaintings_mikolow_text: "Mosaikwand",
    wallpaintings_zabrze_josef_title: "Zabrze, St. Josefkirche",
    wallpaintings_zabrze_josef_text: "Mosaikwand",
    wallpaintings_disclaimer: "Fotos: Marcin Sacha",
    wallpaintings_image: "Bild",
    [`wallpaintings_gallery_labels_${GALLERY_NAME.chorzow}`]: [
      "5m x 5m",
      "5m x 5m",
      "5m x 5m",
      "5m x 5m",
      "Fragment",
      "Fragment",
      "Fragment",
      "Fragment"
    ],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.tarnow}`]: [
      "",
      "Kreuzwegstationen",
      "Fragment",
      "Fragment",
      "Fragment",
      ""
    ],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.swierklany}`]: ["", "", ""],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.zabrzeTeatr}`]: [
      "",
      "Fragment",
      "Fragment",
      "Fragment",
      "Fragment",
      "Fragment"
    ],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.hazlach}`]: [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.zabrzeAnna}`]: [
      "",
      "",
      "",
      ""
    ],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.rybnik}`]: ["", ""],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.jurkow}`]: ["", ""],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.panewniki}`]: [""],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.bujakow}`]: ["", ""],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.mikolow}`]: ["", ""],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.zabrzeJosef}`]: ["", ""]
  },
  [LANG.plPL]: {
    wallpaintings_title:
      "Przykłady malarstwa ściennego powstałe w latach 1959 – 1971 w Polsce",
    wallpaintings_chorzow_title: "Chorzów, kościół św. Floriana",
    wallpaintings_chorzow_text: "Malarstwo na płytach ceramicznych – ",
    wallpaintings_tarnow_title: "Tarnów, Kaplica Ojców Sercanów",
    wallpaintings_tarnow_text: "Malarstwo na płytach ceramicznych",
    wallpaintings_swierklany_title: "Świerklany, Kościół Parafialny",
    wallpaintings_swierklany_text:
      "Ostatnia Wieczerza i fragment drogi krzyżowej. Malarstwo na płytach ceramicznych",
    wallpaintings_zabrze_teatr_title: "Zabrze, Teatr Nowy",
    wallpaintings_zabrze_teatr_text:
      "Mozaika w połączeniu z malarstwem na płytach ceramicznych – 14m x 4.5m",
    wallpaintings_hazlach_title:
      "Haźlach, kościół parafialny – Stacje drogi krzyżowej",
    wallpaintings_zabrze_anna_title: "Zabrze, Kościół św. Anny",
    wallpaintings_zabrze_anna_text:
      "Stan pierwotny (Jedyna dokumentacja.) Obecnie malarstwo zostało „odnowione”.",
    wallpaintings_rybnik_title: "Rybnik, Kościół Matki Boskiej Bolesnej",
    wallpaintings_rybnik_text: "Przykład zabrudzenia przemysłowego",
    wallpaintings_jurkow_title: "Jurków n. Nidą, Kościół Parafialny",
    wallpaintings_jurkow_text: "Fragment malarstwa",
    wallpaintings_panewniki_title: "Panewniki, Bazilika Franciszkanów",
    wallpaintings_panewniki_text:
      "Fragment malarstwa w kopule. Nie istnieje żadna dokumentacja malarstwa.",
    wallpaintings_bujakow_title: "Bujaków, Koścół św. Mikołaja",
    wallpaintings_bujakow_text:
      "Fragment malarstwa ściennego. Malarstwo w tym kościele zostało w 1978 r. zniszczone. Nie ma innej dokumentacji.",
    wallpaintings_mikolow_title: "Mikołów, kościół Salwatorianów",
    wallpaintings_mikolow_text: "Ściana mozaikowa",
    wallpaintings_zabrze_josef_title: "Zabrze, Kościół św. Józefa",
    wallpaintings_zabrze_josef_text: "Ściana mozaikowa",
    wallpaintings_disclaimer: "Foto: Marcin Sacha",
    wallpaintings_image: "obrazek",
    [`wallpaintings_gallery_labels_${GALLERY_NAME.chorzow}`]: [
      "5m x 5m",
      "5m x 5m",
      "5m x 5m",
      "5m x 5m",
      "Fragment",
      "Fragment",
      "Fragment",
      "Fragment"
    ],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.tarnow}`]: [
      "",
      "Stacje drogi krzyżowej",
      "Fragment",
      "Fragment",
      "Fragment",
      ""
    ],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.swierklany}`]: ["", "", ""],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.zabrzeTeatr}`]: [
      "",
      "Fragment",
      "Fragment",
      "Fragment",
      "Fragment",
      "Fragment"
    ],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.hazlach}`]: [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.zabrzeAnna}`]: [
      "",
      "",
      "",
      ""
    ],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.rybnik}`]: ["", ""],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.jurkow}`]: ["", ""],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.panewniki}`]: [""],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.bujakow}`]: ["", ""],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.mikolow}`]: ["", ""],
    [`wallpaintings_gallery_labels_${GALLERY_NAME.zabrzeJosef}`]: ["", ""]
  }
};
