import { LANG } from "../../types/localization";

export const migration = {
  [LANG.deCH]: {
    migration_text:
      "Diese Seite wird zur Zeit technisch überarbeitet. Sie sollte in ein wenigen Tagen wieder verfügbar sein."
  },
  [LANG.plPL]: {
    migration_text:
      "Ta strona jest obecnie poprawiana technicznie. Powinien być dostępny za kilka dni."
  }
};
