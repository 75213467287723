import { migration } from "../common/components/migration/localization";
import { LANG } from "../common/types/localization";
import { atelier } from "../components/atelier/localization";
import { awardMonteCarlo } from "../components/award-monte-carlo/localization";
import { awardZabrze } from "../components/award_zabrze/localization";
import { book } from "../components/book/localization";
import { claim } from "../components/claim/localization";
import { contact } from "../components/contact/localization";
import { exhibitions } from "../components/exhibitions/localization";
import { gallery } from "../components/gallery/localization";
import { legal } from "../components/legal/localization";
import { poland } from "../components/poland/localization";
import { sitemap } from "../components/sitemap/localization";
import { wallpaintings } from "../components/wallpaintings/localization";
import { welcome } from "../components/welcome/localization";
import { deathNotice } from "../components/death-notice/localization";
import { cv } from "../components/cv/localization";
import { wordsAboutWallpaintings } from "../components/words-about-wallpaintings/localization";
import { common } from "./common";

export default {
  [LANG.deCH]: {
    ...common[LANG.deCH],
    ...atelier[LANG.deCH],
    ...welcome[LANG.deCH],
    ...deathNotice[LANG.deCH],
    ...cv[LANG.deCH],
    ...awardMonteCarlo[LANG.deCH],
    ...awardZabrze[LANG.deCH],
    ...book[LANG.deCH],
    ...claim[LANG.deCH],
    ...exhibitions[LANG.deCH],
    ...legal[LANG.deCH],
    ...poland[LANG.deCH],
    ...sitemap[LANG.deCH],
    ...wallpaintings[LANG.deCH],
    ...wordsAboutWallpaintings[LANG.deCH],
    ...migration[LANG.deCH],
    ...gallery[LANG.deCH],
    ...contact[LANG.deCH]
  },
  [LANG.plPL]: {
    ...common[LANG.plPL],
    ...atelier[LANG.plPL],
    ...welcome[LANG.plPL],
    ...deathNotice[LANG.plPL],
    ...cv[LANG.plPL],
    ...awardMonteCarlo[LANG.plPL],
    ...awardZabrze[LANG.plPL],
    ...book[LANG.plPL],
    ...claim[LANG.plPL],
    ...exhibitions[LANG.plPL],
    ...legal[LANG.plPL],
    ...poland[LANG.plPL],
    ...sitemap[LANG.plPL],
    ...wallpaintings[LANG.plPL],
    ...wordsAboutWallpaintings[LANG.plPL],
    ...migration[LANG.plPL],
    ...gallery[LANG.plPL],
    ...contact[LANG.plPL]
  }
};
