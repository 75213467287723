import { LANG } from "../../common/types/localization";

export const wordsAboutWallpaintings = {
  [LANG.deCH]: {
    wordsAboutWallpaintings_title: "Einige Worte über Wandmalereien",
    wordsAboutWallpaintings_subtitle:
      "Beispiele der Wandmalerei sind in den Jahren 1959 - 1971 in Polen entstanden",
    wordsAboutWallpaintings_image_alt:
      "Norbert Jan Paprotny vor einer seiner Wandmalereien",
    wordsAboutWallpaintings_text1:
      "Die Bezeichnung Wandmalerei resultiert aus dem Malgrund, auf dem diese Malereien entstehen. Meistens sind das verputzte Wände eines architektonischen Bauwerkes. Da diese im Grössenverhältnis zu einem Staffeleibild überragend sind, werden sie auch monumentale oder architektonische Malerei genannt. Die ältesten uns bekannten Dokumente der Wandmalerei sind in der zweiten Hälfte des 19. Jahrhunderts, in den Höhlen von Altamira und Lascaux (im Grenzgebiet von Frankreich und Spanien) entdeckt worden. Ihr Alter wird auf ca. 20'000 Jahre geschätzt.",
    wordsAboutWallpaintings_text2:
      "Als nächstes Beispiel bemalter Wände sind uns Malereien in den Gräbern ägyptischer Pharaonen aus verschiedenen Epochen und Dynastien bekannt. Am Wendepunkt unserer Ära wurde die etruskische Stadt Pompeji im Jahre 79 nach einem Ausbruch des Vulkans Vesuv verschüttet. Seit Mitte des 18. Jahrhunderts wurden bei den Ausgrabungen dieser Stadt in Villen der Bewohner aussergewöhnliche Wandmalereien entdeckt. Eine grosse Überraschung für die Archäologen und Kunsthistoriker war der vollkommene Zustand dieser Malereien. Die Frische der Farben und die hervorragende Verbindung der Farbe mit dem Putz, die über 2000 Jahre in ihrem ursprünglichen Zustand geblieben sind, haben bei den Gelehrten Rätsel aufkommen lassen. Nach der Durchführung von Forschungsarbeiten hat sich herausgestellt, dass bei der Ausführung der Malereien natürliche Pigmente mineralischer und organischer Herkunft verwendet wurden und in Freskotechnik ausgeführt waren. Die Freskotechnik ist die älteste und dauerhafteste Technik der Wandmalerei. Das Pigment, aufgelöst in Wasser ohne Bindemittel, wird auf den noch feuchten Kalkmörtel, bestehend aus einer Mischung von gelöschtem Kalk und Sand aufgetragen und versteinert mit dem Verputz.",
    wordsAboutWallpaintings_text3:
      "Die älteste, seit Tausenden von Jahren bekannte Technik der Wandmalerei, ist im Mittelalter fast vollständig in Vergessenheit geraten. Erst im späten Mittelalter hatte man im architektonisch-romanischen Stil versucht, die dauerhafte Technik neu zu entdecken. Die in der Frührenaissance, in der Renaissance und im Barock ausgeführten Malereien waren wieder von sehr hoher Beständigkeit. Als Beispiel dienen hier Wandmalereien von Künstlern wie: Cimabue, Giotto, Rafael, Michelangelo, Tiepolo, um nur einige zu nennen.",
    wordsAboutWallpaintings_text4:
      "Jede Malerei an der Wand verlangt den Einsatz von Techniken, die der Wand das „Atmen“ ermöglichen; demnach also Feuchtigkeit aufnehmen und verdunsten lassen. Aus diesem Grunde wurden seit jeher verschiedene Techniken des Bindens der Farbe mit dem Putz angewendet. Die bekanntesten, ausser der Alfresko-Technik, sind die Kaseintechnik und die Temperatechnik. In der Kaseintechnik werden die Pigmente mit einem Leim aus magerem Quark und Kalk gebunden und auf den trockenen Putz aufgetragen. Diese Technik wird als Fresko secco bezeichnet. In der Temperatechnik werden die Pigmente mit einer Emulsion aus Vogelei und Öl gebunden. Seit Mitte des 20. Jahrhunderts hat man eine neue Technik eingeführt, die auf einer Emulsion aus künstlichen Harzen basiert. Das Bemalen der Wand mit Öl oder Lack bildet einen undurchlässigen Film, der das „Atmen“ verhindert und den Putz mit der Zeit zerstört.",
    wordsAboutWallpaintings_text5:
      "Die Wandmalerei war schon immer eine Auftragskunst. Der Maler eines Staffeleibildes kann über das Format der zu bemalenden Fläche, die Art der Aussage sowohl im formellen als auch thematischen und farblichen Sinne, selbst bestimmen. Er entscheidet über die Technik bzw. verschiedene Techniken im Bild. Die Wandmalerei beschränkt den Künstler auf verschiedene, aus der Architektur resultierende Gegebenheiten; den Stil der Architektur, die architektonische Begrenzung der Wandfläche, die Wahl der Technik und die Lösung des Auftragsthemas im sowohl farblichen als auch formellen Sinne und der Fügung an die architektonischen Gegebenheiten.",
    wordsAboutWallpaintings_text6:
      "Gegen Ende meines Studiums an der Kunstakademie in Krakau, bekam ich das ehrenvolle Angebot von Professor Taranczewski, bei der Ausführung von Wandmalereien in zwei Kirchen mitwirken zu dürfen. Dies war für mich eine grosse Auszeichnung, da ich mich zu dieser Zeit zur selbstständigen Realisierung von Wandmalereien vorbereitet hatte.",
    wordsAboutWallpaintings_text7:
      "Nach dem Diplomabschluss meines Studiums bekam ich den ersten Auftrag, die Kirche in Bujaków auszumalen. Der Entwurf wurde durch die Kunstkommission der Kurie in Katowice genehmigt, sodass die Arbeiten begonnen werden konnten. Ich entschloss mich, die 500 Jahre alte Kirche in der Kalk-Kasein-Technik auszuführen. Die klaren malerischen Formen und das Weglassen unnötiger Dekorationen machten einen grossen Eindruck.",
    wordsAboutWallpaintings_text8:
      "Diese Malerei als Innovation im Bereich der Sakralmalerei in Oberschlesien brachte mir neue Aufträge in den Ortschaften Kokoszyce und Czuchów.",
    wordsAboutWallpaintings_text9:
      "1962 gewann ich den Wettbewerb zur Ausführung der Polychromie in der Basilika der Franziskaner in Panewniki bei Katowice, der grössten Kirche des Franziskanerordens in Oberschlesien. Die Arbeiten begannen 1963 und dauerten zusammen mit der Restaurierung von zwei Seitenkapellen insgesamt zwei Jahre.",
    wordsAboutWallpaintings_text10:
      "Danach folgten weitere Aufträge, doch ich machte mir Sorgen über die Beständigkeit der Malereien im damaligen industriell verschmutzten Oberschlesien.",
    wordsAboutWallpaintings_text11:
      "In der Hoffnung, der Verschmutzung Widerstand zu leisten und grosse Beständigkeit zu schaffen, beschloss ich, einige Aufträge in glasierter Malerei in Form von Mosaiken und Wandmalereien auf glasierten Platten auszuführen. Beispiele der ausgeführten Mosaiken befinden sich in der Salvatorianerkirche in Mikołów (eine Altarwand von 30 ",
    wordsAboutWallpaintings_text12:
      ") sowie in der Seitenkapelle der St. Josefkirche in Zabrze.",
    wordsAboutWallpaintings_text13:
      "Drei Mosaiken im Handelshaus in Zabrze (je 9 ",
    wordsAboutWallpaintings_text14:
      ") wurden beim Umbau des Hauses zerstört. Ein Auftrag für eine Wand von 14 m Länge und 4,5 m Höhe im Foyer des Neuen Theaters in Zabrze hat mich auf den Gedanken gebracht, die Wand aus Mosaik in Verbindung mit keramischen Platten mit symbolischen Theaterthemen zu gestalten. Es ist ein einmalig Monumentales Werk geworden.",
    wordsAboutWallpaintings_text15:
      "Im nächsten Auftrag für die neuartige Kirche St. Florian in Chorzów, beschloss ich vier quadratische Felder, je 25 ",
    wordsAboutWallpaintings_text16:
      " auf der Höhe von 8 bis 13 m, mit Malerei auf keramischen Platten zu gestalten. Es entstand eine Wand aus vier Feldern von 100 ",
    wordsAboutWallpaintings_text17:
      " mit Szenen von der Auferstehung bis zur Ausgiessung des Heiligen Geistes.",
    wordsAboutWallpaintings_text18:
      "Ein weiteres Beispiel der Malerei auf keramischen Platten befindet sich in der Kirche in Swierklany bei Rybnik. Die Komposition der Altarwand hat das Abendmahl zum Thema. Symbolisch stellt die Szene ein beflügeltes Kreuz dar. In der mittleren Senkrechten steht Christus und in der Waagerechten die Aposteln. Durch die in der Bewegung heraus geschnittenen Apostelfiguren in der Waagerechten und dem statischen Balken mit Christusfigur, entsteht der Eindruck eines beflügelten Kreuzes. Die Kreuzwegstationen malend, erlaubte ich mir eine Innovation. In einem Feld unter drei Kirchenfenstern komponierte ich die 14 Kreuzwegstationen in einem Block als Leidensweg eines Tages. Die zwei punktuell ausgerichteten, in die Architektur eingeordneten Szenen aus den letzten Lebenstagen Christi, tragen einen wesentlichen Inhalt des christlichen Glaubens.",
    wordsAboutWallpaintings_text19:
      "Ein weiteres Beispiel der Dauerhaftigkeit der glasierten Malerei ist der einladende Christus an der Aussenwand über dem Haupteingang der Kirche in Bieruń Nowy.",
    wordsAboutWallpaintings_text20:
      "Mein letzter Auftrag war die Malerei und die Kreuzwegstationen in der Kirche in Hażlach bei Cieszyn. Es existieren nur noch die Kreuzwegstationen.",
    wordsAboutWallpaintings_text21:
      "Seit der Zeit, der zum Teil beschriebenen Werke meiner Wandmalereien, sind 40 bis 50 Jahre vergangen. Bis heute sind nur die in glasierten Techniken im Originalzustand erhalten geblieben. Alle anderen Werke meiner Wandmalereien sind ausgelöscht oder so „erneuert“ worden, dass ich mich als Autor zu dieser „Erneuerung“ nicht bekennen will.",
    wordsAboutWallpaintings_text22:
      "Die Wandmalerei war für mich eine grosse Passion. Darüber zeugen meine Träume, in denen ich mich nach so vielen Jahren noch immer auf den Gerüsten bewege nach neuen Lösungen suchend. Die näheren Kontakte mit der Kirche, die mir immer neue malerische Aufträge anvertraute, machte mich für den Staatssicherheitsdienst der Volksrepublik Polen zum Angriffsziel. Durch deren Schikanen wurde ich gezwungen das Land zu verlassen und dadurch wurden ab dieser Zeit meine Arbeiten in dem Bereich unterbrochen.",
    wordsAboutWallpaintings_signature_alt:
      "Unterschrift von Norbert Jan Paprotny"
  },
  [LANG.plPL]: {
    wordsAboutWallpaintings_title: "Kilka słów o malarstwie ściennym",
    wordsAboutWallpaintings_subtitle:
      "Przykłady malarstwa ściennego powstały w latach 1959 – 1971 w Polsce",
    wordsAboutWallpaintings_image_alt:
      "Norbert Jan Paprotny przed jednym ze swoich malowideł ściennych",
    wordsAboutWallpaintings_text1:
      "Określenie malarstwa ściennego wynika z podłoża malarskiego, na którym to malarstwo powstaje. Przeważnie są to otynkowane ściany w architekturze. Ponieważ takie obrazy ścienne wymiarami przewyższają obrazy sztalugowe, określa się je jako malarstwo monumentalne albo architektoniczne. Najstarsze nam znane dokumenty malarstwa ściennego zostały odkryte w drugiej połowie dziewiętnastego wieku w grotach Altamira i Lascaux (w pograniczu Francji i Hiszpanii). Wiek tych malowideł jest na około 20'000 lat oceniany.",
    wordsAboutWallpaintings_text2:
      "Następny przykład malowanych ścian znajdujemy w grobowcach egipskich faraonów z różnych epok i dynastii. Na przełomie naszej ery w roku 79 zasypane zostało etruskie miasto Pompea przez wybuch wulkanu Wezuwiusz. W połowie wieku osiemnastego przy wykopaliskach odkryte zostały w wilach mieszkańców tego miasta malarstwa ścienne. Wielkim zaskoczeniem dla archeologów i historyków sztuki był doskonały stan tych malowideł. Czystość kolorów jak i doskonałe połączenie farby z tynkiem, które przetrwały 2000 lat w stanie pierwotnym, narzuciły naukowcom szereg zagadkowych pytań. Po przeprowadzonych pracach badawczych okazało się, że przy wykonaniu tych malowideł użyto pigmentów naturalnych pochodzenia mineralnego i organicznego w technice alfresko. Ta technika łączenia pigmentu z podłożem, jest naistarszą i najbardziej trwałą techniką malarstwa ściennego. W tej technice nakłada się pigment rozpuszczony w wodzie bez spoiwa na jeszcze wilgotne podłoże zaprawy. Barwnik (pigment) przy wysychaniu kamienieje razem z zaprawą i staje się trwały jak sama zaprawa. Zaprawa, to mieszanina wapna gaszonego z piaskiem.",
    wordsAboutWallpaintings_text3:
      "Ta najstarsza, od tysięcy lat znana technika malarstwa ściennego została w średniowieczu prawie całkowicie zapomniana. Dopiero w późnym średniowieczu, w stylu romańskim, zaczęto tę trwałą technikę odkrywać od nowa. We wczesnym renesansie, w renesansie i baroku wykonane malowidła osiągnęły znowu wysoką trwałość. Jako przykład służą tu malowidła ścienne wykonane przez artystów takich jak Cimabue, Giotto, Rafael, Michelangelo, Tiepolo, by tylko niektórych wymienić.",
    wordsAboutWallpaintings_text4:
      "Każde malarstwo na ścianie wymaga zastosowania technik malarskich, które pozwalają ścianie „oddychać” czyli wsiąkać wilgoć jak również wyparowywać. Z tego względu stosowano od dawna różne techniki wiązania koloru z podłożem tynku. Najbardziej znane, oprócz techniki alfresko, są technika kazeinowa i technika temperowa. W technice kazeinowej wiązane są pigmenty z klejem wytworzonym z chudego sera białego i gaszonego wapna. Tak związana barwa nakładana zostaje na suchy tynk i znana jest jako technika fresko secco (suchy fresk). W technice temperowej pigmenty wiązane są emulsją wytworzoną z jaja ptasiego i oleju. Od połowy XX wieku wprowadzono nową technikę emulsyjną opartą na sztucznych żywicach. Spoiwa z oleju lub lakieru tworzą jednolity film, nie pozwalający ścianie „oddychać”i powodują z biegiem czasu niszczenie tynku.",
    wordsAboutWallpaintings_text5:
      "Malarstwo ścienne było zawsze sztuką zleceniową, ograniczającą artystę w różnych z architektury wynikających przesłanek: stylu, ograniczonej płaszczyzny ściany, wybóru techniki i rozwiązania tematu pod względem formalnym, podporządkowując się istniejącej architekturze.",
    wordsAboutWallpaintings_text6:
      "Pod koniec moich studiów w Krakowskiej Akademii Sztuk Pięknych otrzymałem zaszczytną propozycję profesora Wacława Taranczewskiego do współdziałania przy wykonaniu malarstwa ściennego w dwóch kościołach. Było to dla mnie wielkie wyróżnienie, gdyż w tym czasie przygotowywałem się do samodzielnego wykonastwa malarstwa ściennego.",
    wordsAboutWallpaintings_text7:
      "Po zakończeniu moich studiów, otrzymałem pierwsze zlecenie malowania kościoła w Bujakowie. Projekt został przez komisją kulturalną Kurii Biskupiej w Katowicach zatwierdzony, więc mogłem rozpocząć realizację. Zdecydowałem się w tej 500 letniej świątyni zastosować technikę wapienno-kazeinową. Czyste formy malarskie i odrzuczenie zbędnej dekoracji zrobiły duże wrażenie.",
    wordsAboutWallpaintings_text8:
      "Ta inowacja w zakresie malarstwa sakralnego na Śląsku była powodem następnych zleceń w miejscowościach Kokoszyce i Czuchów.",
    wordsAboutWallpaintings_text9:
      "W 1962 roku wygrałem konkurs wykonania polichromii w Bazylice Franciszkanów w Panewnikach koło Katowic, największego kościoła Zakonu Franciszkanów na Śląsku. Rozpoczęte w 1963 roku prace trwały, łącznie z restauracją dwóch kaplic, w sumie dwa lata.",
    wordsAboutWallpaintings_text10:
      "Potem otrzymywałem następne zlecenia, lecz mnie gnębiły wątpliwości odnośnie wytrzymałości tych malowideł na ówczesnym przemysłowo zabrudzonym Górnym Sląsku.",
    wordsAboutWallpaintings_text11:
      "W nadziei zapobieżenia tym zabrudzeniom postanowiłem niektóre zlecenia wykonać w technice glazurowej w formie mozaiki i malarstwa na płytach ceramicznych. Przykłady wykonanych mozaik znajdują się w kościele Salwatorianów w Mikołowie (ściana ołtarzowa 30 ",
    wordsAboutWallpaintings_text12:
      ") i w kaplicy bocznej kościoła św. Józefa w Zabrzu.",
    wordsAboutWallpaintings_text13:
      "Trzy mozaiki w domu handlowym w Zabrzu (każda 9 ",
    wordsAboutWallpaintings_text14:
      ") zostały zniszczone w czasie przebudowy tego domu. Otrzymane zlecenie na malowanie ściany o rozmiarach 14 m dlugości i 4,5 m wysokości w Teatrze Nowym w Zabrzu postanowiłem wykonać w mozaice, w połączeniu z malarstwem na płytach ceramicznych. Ta ściana, wzbogacona symboliczną treścią z życia teatralnego, robi monumentalne wrażenie.",
    wordsAboutWallpaintings_text15:
      "W następnym zleceniu współczesnego kościoła św. Floriana w Chorzowie postanowiłem wykonać malarstwo na płytach ceramicznych. Cztery pola kwadratowe przedzielone pilastrami,umieściłem na wysokości od 8 do 13 m. Ściana tych czterych pól, z tematyką od Zmartwychwstania do Zesłania Ducha św., ma wymiar stu metrów kwadratowych.",
    wordsAboutWallpaintings_text16: "",
    wordsAboutWallpaintings_text17: "",
    wordsAboutWallpaintings_text18:
      "Następny przykład malarstwa na płytach ceramicznych znajduje się w kościele w Świerklanach koło Rybnika. Kompozycja ściany ołtarzowej posiada temat Ostatniej Wieczerzy. Temat ten przedstawiłem symbolicznie w krzyżu uskrzydlonym. W belce pionowej umieściłem Chrystusa, a w belce poziomej apostołów. Poprzez wycięcie figur apostołów, w zależności od ich ruchów w belce poziomej i statycznej figury Chrystusa w belce pionowej, powstaje wrażenie uskrzydlonego krzyża.",
    wordsAboutWallpaintings_text19:
      "Następnym przykładem odporności malarstwa glazurowanego na warunki atmosferyczne jest Chrystus zapraszający, umieszczony na zewnętrznej ścianie nad wejściem do kościoła w Bieruniu Nowym.",
    wordsAboutWallpaintings_text20:
      "Moim ostatnim zleceniem było malarstwo ścienne i stacje drogi krzyżowej w kościele w Hażlach koło Cieszyna. Zachowały się tylko stacje drogi krzyżowej.",
    wordsAboutWallpaintings_text21:
      "Od czasu powstania wyżej opisanych niektórych prac mojego malarstwa ściennego minęło 40 do 50 lat. Do dnia dzisiejszego utrzymały się tylko malarstwa ścienne w technice glazurowanej w stanie pierwotnym. Wszelkie inne dzieła mojego malarstwa ściennego zostały zniszczone albo „odnowione” w taki sposób, że trudno mi się przyznać do ich autorstwa.",
    wordsAboutWallpaintings_text22:
      "Malarstwo ścienne uprawiałem z wielką pasją. Świadczą o tym moje sny, w których do tej pory po dziesiątkach lat, na rusztowaniach chodzę w poszukiwaniu nowych rozwiązań. Poprzez bliższe kontakty z Kościołem, który mi nowe malarskie zlecenia powierzał, stałem się dla Urzędu Bezpieczeństwa PRL objektem ataku. Przez szykany z ich strony zmuszony zostałem opuścić kraj i dlatego od tej pory przerwana została moja twórczość w tej dziedzinie.",
    wordsAboutWallpaintings_signature_alt: "Podpis Norberta Jana Paprotnego"
  }
};
