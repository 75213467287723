import * as React from "react";
import { useContext } from "react";
import { LANG } from "../types/localization";

export const LocalizationContext = React.createContext({
  lang: LANG.deCH,
  localize: localizationKey => localizationKey
});

type IUseLocalized = (localizationKey: string) => string | string[];

export const useLocalized: IUseLocalized = localizationKey => {
  const { localize } = useContext(LocalizationContext);
  return localize(localizationKey);
};
