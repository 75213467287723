import * as React from "react";
import { FC, ReactNode } from "react";
import { LanguageSwitch } from "../../../common/components/language-switch/language-switch";
import { LANG } from "../../../common/types/localization";
import { HeaderNavigation } from "../../navigation/header-navigation";
import classes from "./header.module.css";

interface IHeader {
  children: ReactNode;
}

export const Header: FC<IHeader> = ({ children }) => (
  <div className={classes.header}>{children}</div>
);

interface IHeaderContent {
  lang: LANG;
}

export const HeaderContent: FC<IHeaderContent> = ({ lang }) => (
  <>
    <HeaderNavigation lang={lang} />
    <LanguageSwitch lang={lang} />
  </>
);
