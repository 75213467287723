import { LANG } from "../common/types/localization";

export const common = {
  [LANG.deCH]: {
    locale: "de-CH",
    locale_url_part: "de-ch"
  },
  [LANG.plPL]: {
    locale: "pl-PL",
    locale_url_part: "pl-pl"
  }
};
