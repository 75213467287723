import * as React from "react";
import { FC, ReactNode } from "react";
import { LayoutDefault } from "./default";
import { LANG } from "../../../common/types/localization";
import { HeaderContent } from "./header";
import { MainContent } from "./main";
import { FooterContent } from "./footer";
import { LocalizationContext } from "../../../common/localization/localize";
import messages from "../../../localizations";

interface IDefaultLayout {
  lang: LANG;
  entrypoint?: string;
  page?: string;
  children: ReactNode;
}

export const DefaultLayout: FC<IDefaultLayout> = ({ lang, children }) => {
  return (
    <LocalizationContext.Provider
      value={{
        lang,
        localize: localizationKey => messages[lang][localizationKey]
      }}
    >
      <LayoutDefault>
        {{
          header: <HeaderContent lang={lang} />,
          main: <MainContent>{children}</MainContent>,
          footer: <FooterContent lang={lang} />
        }}
      </LayoutDefault>
    </LocalizationContext.Provider>
  );
};
