import { LANG } from "../../common/types/localization";

export const book = {
  [LANG.deCH]: {
    book_title: "Beflügeltes Leiden",
    text_title: "Erzählungen die das Leben schreibt",
    text_description_1:
      "Norbert Jan Paprotny, 1928 in Polen geboren, beschreibt in seinem Buch beflügelte Abschnitte, aber auch Enttäuschungen seines Lebens in der geschichtlich bewegten Zeit des 20. und Anfang des 21. Jahrhunderts in Polen und in der Schweiz.",
    text_description_2:
      "274 Seiten, davon 32 Seiten farbige Reproduktionen seiner Bilder. Bestellung per E-Mail oder Post: ",
    text_description_3: ", Preis CHF 39 plus Versandkosten",
    text_link: "Kontakt",
    text_link_url: "/de-ch/kontakt",
    image_alt_text: 'Buch "Beflügeltes Leiden"'
  },
  [LANG.plPL]: {
    book_title: "Uskrzydlone cierpienie",
    text_title: "Opowieści przez życie pisane",
    text_description_1:
      "Norbert Jan Paprotny urodzony 1928 r. w polsce opisuje w swojej Książce (napisanej w języku niemieckim) uskrzydlone odcinki jak również rozczarowania swego życia w historycznie ruchliwym czasie dwudziestego i początkiem dwudziestegopierwszego stulecia w Polsce i w Szwajcarii.",
    text_description_2:
      "274 stron w których znajduje się 32 kolorowych reprodukcji jego obrazów. Zamówienia: E-Mail a Pocztą: ",
    text_description_3: ", Cena CHF 39 oraz koszt przesyłki",
    text_link: "Kontakt",
    text_link_url: "/de-ch/kontakt",
    image_alt_text: 'Książka "Beflügeltes Leiden"'
  }
};
