import * as React from "react";
import { FC } from "react";
import { LANG } from "../../common/types/localization";
import { headerNavigationItems } from "./data";
import { Navigation } from "./navigation";

import theme from "./header-navigation-theme.module.css";

interface IHeaderNavigation {
  lang: LANG;
}

export const HeaderNavigation: FC<IHeaderNavigation> = ({ lang }) => (
  <Navigation lang={lang} theme={theme} items={headerNavigationItems} />
);
