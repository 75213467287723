import * as React from "react";
import { FC } from "react";
import { LANG } from "../../common/types/localization";
import { footerNavigationItems } from "./data";
import { Navigation } from "./navigation";

import theme from "./footer-navigation-theme.module.css";

interface IFooterNavigation {
  lang: LANG;
}

export const FooterNavigation: FC<IFooterNavigation> = ({ lang }) => (
  <Navigation lang={lang} theme={theme} items={footerNavigationItems} />
);
