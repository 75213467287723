import { LANG } from "../../common/types/localization";

export const atelier = {
  [LANG.deCH]: {
    atelier_title: "Der Künstler Norbert Jan Paprotny im Atelier",
    atelier_hero_alttext: "Norbert Jan Paprotny im Atelier",
    atelier_listentry_0:
      "Geboren am 6. Mai 1928 in Zabrze - Pawłów in Oberschlesien-Polen, gestorben am 2. Juli 2018 in der Schweiz",
    atelier_listentry_1: "Absolvent der Kunstakademie Krakau",
    atelier_listentry_2: "Mitglied des polnischen Künstlerverbandes",
    atelier_listentry_3:
      "1962 Auszeichnung an der Internationalen Ausstellung der Malerei in Monte Carlo",
    atelier_listentry_4: "Zahlreiche Ausstellungen in Polen",
    atelier_listentry_5: "1959 bis 1971 Ausführung vieler Wandmalereien",
    atelier_listentry_6: "1972 in der Schweiz als Flüchtling aufgenommen",
    atelier_listentry_7:
      "1979 bis 1993 Gründung und Führung einer privaten Kunstschule für Erwachsenenbildung",
    atelier_listentry_8: "Seit 1987 Bürgerrecht der Stadt Zürich",
    atelier_listentry_9:
      "2003 Ehrung mit dem Kulturpreis der Heimatstadt Zabrze",
    atelier_listentry_10:
      "Seit 1974 - 17 Ausstellungen in der Schweiz und im Ausland"
  },
  [LANG.plPL]: {
    atelier_title: "Artysta Norbert Jan Paprotny w pracowni",
    atelier_hero_alttext: "Norbert Jan Paprotny w pracowni",
    atelier_listentry_0:
      "Urodzony 6 maja 1928 w Zabrzu - Pawłowie na Górnym Sląsku – zmarł 2 lipca 2018 w Szwajcarii, gdzie mieszkał",
    atelier_listentry_1: "Absolwent Akademii Sztuk Pięknych w Krakowie",
    atelier_listentry_2: "Członek Związku Polskich Artystów Plastyków",
    atelier_listentry_3:
      "1962 wyróżniony na międzynarodowej wystawie malarstwa w Monte Carlo",
    atelier_listentry_4:
      "Udział w licznych wystawach ogólnopolskich, okręgowych i indywidualnych",
    atelier_listentry_5:
      "W latach 1959 – 1971 prace z zakresu malarstwa ściennego",
    atelier_listentry_6: "Od 1972 jako uchodźca w Szwajcarii",
    atelier_listentry_7:
      "1979 – 1993 prowadzenie własnej szkoły artystycznej dla dorosłych w Zurychu",
    atelier_listentry_8: "Od 1987 obywatelstwo miasta Zurychu",
    atelier_listentry_9:
      "2003 uhonorowany Nagrodą Kultury rodzinnego miasta Zabrze",
    atelier_listentry_10: "Od 1974 - 17 wystaw w Szwajcarii i w innych krajach"
  }
};
