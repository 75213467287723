import * as React from "react";
import { FC, ReactNode, useState, useEffect } from "react";

interface IUnsupportedBrowserMessageProps {
  children: ReactNode;
}

export const UnsupportedBrowserMessage: FC<IUnsupportedBrowserMessageProps> = ({
  children
}) => {
  const [isIe, setIsIe] = useState(false);
  useEffect(() => {
    console.log({
      window: Boolean(window),
      navigator: Boolean(window.navigator),
      userAgent: Boolean(window.navigator.userAgent)
    });
    if (window && window.navigator && window.navigator.userAgent) {
      console.log({ ie: window.navigator.userAgent.indexOf("MSIE ") > -1 });
      if (
        window.navigator.userAgent.indexOf("MSIE ") > -1 ||
        Boolean(window.navigator.userAgent.match(/Trident.*rv:11\./))
      ) {
        setIsIe(true);
      }
    }
  }, []);
  const ieMessageDe =
    "Sie benutzen Internet Explorer. Dieser Browser wird nicht mehr unterstützt. Bitte verwenden Sie einen modernen Browser wie Chrome, Firefox, oder Edge.";
  const ieMessagePl =
    "Używasz Internet Explorera. Ta przeglądarka nie jest już obsługiwana. Użyj nowoczesnej przeglądarki, takiej jak Chrome, Firefox lub Edge.";
  if (isIe) {
    return (
      <>
        <div>{ieMessageDe}</div>
        <div>{ieMessagePl}</div>
      </>
    );
  }
  return children;
};
