import { LANG } from "../../common/types/localization";

export const deathNotice = {
  [LANG.deCH]: {
    death_notice_title: "Todesanzeige",
    death_notice_images: ["todesanzeige_de-e5e7ab1b.jpg"]
  },
  [LANG.plPL]: {
    death_notice_title: "Pośmiertny",
    death_notice_images: [
      "todesanzeige_pl.jpg",
      "Dziennik-Zachodny-3e6fdca0.jpg",
      "Glos-Nr-28-vom-12-07-2018-3fc5f54a.jpg",
      "Gosc-Katowicki-efd76dfb.jpg"
    ]
  }
};
