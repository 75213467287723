import * as React from "react";
import { FC } from "react";
import { FancyLine } from "../common/components/lines/fancy-line";
import { Migration } from "../common/components/migration/migration";
import {
  Heading,
  HEADING_CLASS
} from "../common/components/typography/heading";
import { useLocalized } from "../common/localization/localize";
import { ILocalizedPage } from "../common/types/page";

export enum GALLERY_NAME {
  gallery = "gallery",
  chorzow = "chorzow",
  tarnow = "tarnow",
  swierklany = "swierklany",
  zabrzeTeatr = "zabrze-teatr",
  hazlach = "hazlach",
  zabrzeAnna = "zabrze-anna",
  rybnik = "rybnik",
  jurkow = "jurkow",
  panewniki = "panewniki",
  bujakow = "bujakow",
  mikolow = "mikolow",
  zabrzeJosef = "zabrze-josef"
}

interface IGalleryEndpoint extends ILocalizedPage {
  name: GALLERY_NAME;
}

export const GalleryEndpoint: FC<IGalleryEndpoint> = ({
  lang: _lang,
  name: _name
}) => {
  const title = useLocalized("gallery_title");
  return (
    <>
      <Heading level={1} theme={HEADING_CLASS.MAIN}>
        {title}
      </Heading>
      <FancyLine />
      <Migration />
    </>
  );
};
